import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronDown, faBars, faUsers, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import Notifications from "../notifications/notifications";
import NextLesson from "../lessons/components/next_lesson";
import StaffNav from "./nav-lists/staff_nav";
import TeacherNav from "./nav-lists/teacher_nav";
import StudentNav from "./nav-lists/student_nav";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, getUserProfile } from "../user/userSlice";
import Alert from '../toolbox/alert'
import { setOpenNavbar, setOpenResponsiveFilters, setResponsiveOne } from "./navigationSlice";
import i18n from '../../i18n'
import cp from '../images/icons/CP-green.svg'
import ce from '../images/icons/CE-green.svg'
import students from '../images/icons/students-green.svg'
import faqIcon from '../images/icons/faq.svg'
import HumanResourceNav from "./nav-lists/hr_nav";
import MultiLanguage from "../user/component/multiLanguage";

const Navigation = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.userData)
  const navigate = useNavigate();
  const error = useSelector(state => state.lessons.error)
  const errorCC = useSelector(state => state.coursCollectives.error)
  const userProfiles = useSelector((state) => state.user.userProfile)
  const ref = useRef(null);
  const navbarRef = useRef(null);
  const basePath = useLocation().pathname;
  const [showNavbar, setShowNavbar] = useState(false)
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const openResponsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const profile = useSelector((state)=>state.user.currentProfile)
  const errorAv = useSelector(state => state.availabilities.error)


  useEffect(() => {
    dispatch(fetchUser())
  }, [profile]);

  useEffect(() => {
    if ((error && error.length > 0) || (errorCC && errorCC.length > 0) || (errorAv && errorAv.length > 0)) {
      ref.current?.onShow()
    }
  }, [error, errorCC,errorAv]);


  useEffect(() => {
    if ((window.screen.width < 768 || responsiveOne) && showNavbar) {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
    else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

  }, [showNavbar]);

  useEffect(() => {

    if (window.screen.width < 768) {
      dispatch(setResponsiveOne(true));

    } else {
      dispatch(setResponsiveOne(false));
    }
  }, [])




  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const handleWindowResize = useCallback((event) => {
    if (window.screen.width < 768 || window.innerWidth < 768) {
      dispatch(setResponsiveOne(true));

    } else {
      dispatch(setResponsiveOne(false));
      dispatch(setOpenResponsiveFilters(false))
    }
  }, []);

  const handleStopImpersonating = () => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    try {
      fetch('/api/v1/users/stop_impersonating', {
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": csrfToken
        },
        method: 'POST',
        body: JSON.stringify({})
      }).then(response => {
        if (!response.ok) {
          throw new Error('Failed to stop impersonating');
        }
         window.location.replace( user?.role ==='Student' ? '/a_trainings' : '/teachers');
      })
    } catch (error) {
      console.error('Error stopping impersonation:', error);
    }
  };

  const handleOutsideClick = (e) => {

    if (navbarRef?.current && !(navbarRef?.current?.contains(e?.target))) {

      setShowNavbar(!showNavbar)
      dispatch(setOpenNavbar(!showNavbar))

    }
  };

  const handleNavBarOpening = () => {
    if (location?.href?.includes('pm=s') || location?.href?.includes('pm=m')) {
      navigate(basePath)
    }

    setShowNavbar(true)
    dispatch(setOpenNavbar(true))
  }


  const logoutUser = () => {
    const url = "/users/sign_out";
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
    }).then(r => {
      location.reload();
    })
      .catch(error => console.log(error))
  }

  const generateBottomNavigationByRole = (role) => {
    if (role === 'Student') {
      return (
        <>
          <span
            onClick={() => navigate('/s_lessons')}
            style={{ display: 'flex', justifySelf: 'flex-end', alignContent: 'center', marginRight: '16px' }}
          >
            <img style={{ width: '60px', height: '60px' }} src={cp} />
          </span>

          <span
            onClick={() => navigate('/elearnings')}
            style={{ width: '60px', height: '60px', display: 'flex', justifySelf: 'flex-end', alignContent: 'center' }}
          >
            <img src={ce} />
          </span>
        </>
      );
    }

    if (role === 'Teacher') {
      return (
        <>
          <span
            onClick={() => navigate('/t_lessons')}
            style={{ display: 'flex', justifySelf: 'flex-end', alignContent: 'center', marginRight: '16px' }}
          >
            <img style={{ width: '60px', height: '60px' }} src={cp} />
          </span>

          <span
            onClick={() => navigate('/t_trainings')}
            style={{ width: '60px', height: '40px', display: 'flex', justifySelf: 'flex-end', alignContent: 'center', marginTop: '10px' }}
          >
            <img src={students} />
          </span>
        </>
      );
    }

    if (role === 'Admin') {
      return (
        <>
          <span
            onClick={() => navigate('/a_lessons')}
            style={{ display: 'flex', justifySelf: 'flex-end', alignContent: 'center', marginRight: '16px' }}
          >
            <img style={{ width: '60px', height: '60px' }} src={cp} />
          </span>

          <span
            onClick={() => navigate('/a_trainings')}
            style={{ width: '60px', height: '40px', display: 'flex', justifySelf: 'center', alignContent: 'center', marginTop: '10px' }}
          >
            <img src={students} />
          </span>
        </>
      );
    }

    return null;
  }


  return (
    <>

      {/* reverse logic with navbar */}
      <div className={`menu-icon ${openResponsiveFilter ? 'close' : (!showNavbar ? 'open' : 'close')}`}
      >
        <FontAwesomeIcon onClick={() =>
          handleNavBarOpening()} icon={faBars} style={{ color: "#FFD43B", width: '40px', height: '35px', display: 'flex' }} />

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {generateBottomNavigationByRole(user.role)}
        </div>


      </div>



      <div ref={navbarRef} className={`navigation ${showNavbar ? 'showNavbar' : 'noShowBar'}`}>

        <div className="entete">
          <div className="logo" ></div>
          <div className="user-bloc">
            <div className="line">
              <div className="user-name">{user?.name}</div>
              {user.role !== 'Admin' && <Notifications />}
              {/* <Notifications /> */}
            </div>
            <div className="line">
              {
                user?.role ==='Student' && <MultiLanguage />
              }



            </div>
          </div>
        </div>



        {
          (user && (user.role == "Student" || user.role == "Teacher")) &&
          <NextLesson />
        }

        {
          (user && user.role == "Student") &&
          <StudentNav setShowNavbar={setShowNavbar} setOpenNavbar={setOpenNavbar} showNavbar={showNavbar} />
        }
        {
          (user && user.role == "Teacher") &&
          <TeacherNav setShowNavbar={setShowNavbar} setOpenNavbar={setOpenNavbar} showNavbar={showNavbar} />
        }

        {
          (user && (user.role == "Admin" || user.role == "Superadmin")) &&
          <StaffNav setShowNavbar={setShowNavbar} setOpenNavbar={setOpenNavbar} showNavbar={showNavbar} />
        }
        {
          (user && user.role == "HumanResource") &&
          <HumanResourceNav setShowNavbar={setShowNavbar} setOpenNavbar={setOpenNavbar} showNavbar={showNavbar} />
        }

        <div className="footer-link">

          {/* {
            (user && (user.role == 'Student')) &&
            <div className="extra-links">
              <div className="navigate-link">
                <a onClick={() => {
                  const path = user?.role === 'Teacher' ? '/teacher' : '/student';
                  navigate(path);
                }}>
                  {i18n.t('navigation.student.profile')}
                </a>
              </div>
            </div>
          } */}

          <div className="extra-links">

            {
              (user && user.role == "Student") &&
              <div className="logout link">
                <a href="https://cercledeslangues.stonly.com/kb/fr" className="green-link" target="_blank">
                  <div className="icon green">
                    <img className="icon-details left" src={faqIcon} />
                  </div>
                  {i18n.t('navigation.help-center')}
                </a>
              </div>
            }

            {
              (user && user.role == "Teacher") &&
              <div className="logout link">
                <a href="https://teachers.stonly.com/kb/en" className="green-link" target="_blank">
                  <div className="icon green">
                    <img className="icon-details left" src={faqIcon} />

                  </div>
                  {i18n.t('navigation.help-center')}
                </a>
              </div>
            }

            {
              (user && user.role == "Student") &&
              <div className="logout link"
                onClick={() => {
                  if (responsiveOne) {
                    setShowNavbar(!showNavbar)
                    dispatch(setOpenNavbar(!showNavbar))
                  }
                }}
              >
                <Link to={"/parrainage"} style={{fontWeight:'600', color:'black'}} >
                  <div className="icon"
                  >
                    <FontAwesomeIcon  className="icon-details left" icon={faUsers} color="black" />
                  </div>
                  {i18n.t('navigation.sponsorship')}
                </Link>
              </div>
            }

            {
              (user && user.impersonate_active) &&
              <div className="logout link"
                onClick={() => {
                  if (responsiveOne) {
                    setShowNavbar(!showNavbar)
                    dispatch(setOpenNavbar(!showNavbar))
                  }
                }}
              >
                <a onClick={handleStopImpersonating}>
                  <div className="icon"
                  >
                    <FontAwesomeIcon className="icon-details left" icon={faArrowLeft} color="#C6C6C6" />
                  </div>
                  Stop impersonate
                </a>
              </div>
            }

            {
              user?.is_super_admin &&
              <div className="logout link">
                <a href={`${window.location.origin}/admin`}>
                  <div className="icon">
                    <FontAwesomeIcon className="icon-details left" icon={faExternalLinkSquareAlt} color="#C6C6C6" />
                  </div>
                  {i18n.t('navigation.backoffice')}
                </a>
              </div>
            }

            <div className="logout link">
              <a onClick={logoutUser}>
                <div className="icon">
                  <FontAwesomeIcon className="icon-details left" icon={faArrowLeft} color="#C6C6C6" />
                </div>
                {i18n.t('navigation.logout')}
              </a>
            </div>



          </div>
        </div>


      </div>


      <Alert error={error || errorCC || errorAv} ref={ref} />
    </>
  )

};

export default Navigation;
