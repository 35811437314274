import React, {useEffect, useState, useRef} from "react";
import {OverlayTrigger, Popover} from 'react-bootstrap';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Event = (eventInfo) => {

    if (eventInfo.event?._def?.extendedProps?.availabilities?.length > 0) {
        return(
            <div id={`event-${eventInfo.event?._def?.id}`} className={`event grouped-availabilities`}>
                <Link className="availabilities-actions" to={`?avs=${JSON.stringify(eventInfo.event?._def?.extendedProps.availabilities)}&pm=m`}>
                    <div className="inner-event">
                        <span>{eventInfo.event.title}</span>
                    </div>
                </Link>
            </div>
          )
    } else {
        if (eventInfo.event?._def?.title?.length > 1) {
            return (
                <div id={`event-${eventInfo.event?._def?.id}`} className={`event grouped-availabilities`}>
                    <Link className="availabilities-actions" to={`/a_tickets/availability`}>
                        <div className="inner-event">
                            <span style={{color: "grey"}}>{eventInfo.event.title}</span>
                        </div>
                    </Link>
                </div>
            )
        } else {
            return (
                <div id={`event-${eventInfo.event?._def?.id}`} className={`event grouped-availabilities`}>
                    <Link className="availabilities-actions" to={`?s=${eventInfo.event?._def?.publicId}&pm=m&state=close_slot`}>
                        <div className="inner-event">
                            <span>{eventInfo.event.title}</span>
                        </div>
                    </Link>
                </div>
            )
        }



    }



};


export default Event;
