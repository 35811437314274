import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThLarge, faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import i18n from '../../../i18n'

const HumanResourceNav = ({ setShowNavbar, showNavbar, setOpenNavbar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.user.userData)
  const loading = useSelector(state => state.lessons.loading)
  const dispatch = useDispatch()

  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  const handleResponsiveNavbar = () => {
    if (responsiveOne) {
      setShowNavbar(!showNavbar)
      dispatch(setOpenNavbar(!showNavbar))
    }

  }

  return (
    <div className="nav-list">
      <Link className={`nav-el`} to={'/h_trainings'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname == '/h_trainings') && 'active'}`}>
          <span className="nav-icon students"></span>
          {i18n.t('navigation.human-resource.trainings')}
        </div>
      </Link>

      <Link className="nav-el" to={'https://elearning.cercledeslangues.com/'} target="_blank">
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname == '/e-dashboard') && 'active'}`}>
          <span className="nav-icon dashboard"></span>
          {i18n.t('navigation.human-resource.e-dashboard')}
          </div>
      </Link>


      <Link className="nav-el" to={user?.invite_link || '#'} target={user?.invite_link ? "_blank" : "_self"}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname === '/invite') ? 'active' : ''}`}>
          <span className="nav-icon invite"></span>
          {i18n.t('navigation.human-resource.invite')}
        </div>
      </Link>




    </div>
  )
}

export default HumanResourceNav;
