import React, {useEffect, useState, useRef} from "react";
import {OverlayTrigger, Popover} from 'react-bootstrap';
import { useSelector } from "react-redux";
import EventDetail from './event_detail';
import { Link } from "react-router-dom";
import i18n from '../../../i18n'

const Event = (eventInfo) => {

    const overlay = (
      <Popover id="popover-basic">
          <EventDetail event={eventInfo.event?._def}/>
      </Popover>
    )

    // case availability
    if (eventInfo.event.title == "Availability") {
      return (
      <div id={`event-${eventInfo.event?._def?.id}`} className={`event availability`}>
          <Link className="lesson-state-actions" to={`?a=${eventInfo.event?.id}&pm=m&state=booking`}>
            <div className="inner-event">
            </div>
          </Link>
        </div>
      )
    // case closing availability
    } else if (eventInfo.event?._def?.extendedProps?.status == "canceling" ) {
      return (
          <div id={`event-${eventInfo.event?._def?.id}`} className={`event canceling-availability`}>
              <Link className="availabilities-actions" to={`/t_tickets/availability`}>
                  <div className="inner-event">
                      <span style={{color: "grey"}}>{eventInfo.event.title}</span>
                  </div>
              </Link>
          </div>
      )
    // case pending recredit lesson
    } else if (eventInfo.event?._def?.extendedProps?.pending_ticket ) {
      return(
          <div id={`event-${eventInfo.event?._def?.id}`} className={`event lesson pending-ticket ${eventInfo.event?._def?.extendedProps?.lesson_state}`}>
              <OverlayTrigger key={eventInfo.event?._def?.id} trigger="click" containerPadding={20} rootClose overlay={overlay}>
                <div className="inner-event">
                  <span>{eventInfo.event.title}</span>
                </div>
              </OverlayTrigger>
          </div>
        )
    // case interactable lesson
    } else if (eventInfo.event?._def?.extendedProps?.is_current_user_lesson) {
      return(
          <div id={`event-${eventInfo.event?._def?.id}`} className={`event lesson ${eventInfo.event?._def?.extendedProps?.lesson_state}`}>
              <OverlayTrigger key={eventInfo.event?._def?.id} trigger="click" containerPadding={20} rootClose overlay={overlay}>
                <div className="inner-event">
                  <span>{eventInfo.event.title}</span>
                </div>
              </OverlayTrigger>
          </div>
        )
    // case not interactable lesson
    } else {
      return(
        <div id={`event-${eventInfo.event?._def?.id}`} className={`event lesson other-student`}>
          <div className="inner-event">
            <span>{eventInfo.event.title}</span>
          </div>
        </div>
      )
    }

};


export default Event;
