
import React, { useRef, useState } from "react"

import { faPhoneAlt, faMale, faDownload, faExternalLinkAlt, faChevronUp, faChevronDown, faUserFriends, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from '../../../../i18n'
import Summary from "../summary";
import { Collapse } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from "react-redux";
import TestWidget from "../testWidget";
import TestResult from "../testResult";


export default function StepThree({ data }) {
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  const renderBilanIntermediate = () => {
    if (data?.bpt_done) {
      return (
        <>
          <h3>{i18n.t('views.trainings.chart.midterm_overview')}</h3>
            <p>{i18n.t("views.trainings.chart.passed_midterm", {date: data?.bpt_date, manager: data?.bpt_with})}</p>
            <p className="bloc-divider">
              {i18n.t('views.trainings.chart.five_star')} <a href="https://www.google.com/search?q=cercle+des+langues+google&rlz=1C1CHBF_frFR924FR925&oq=cercle+des+langues+google&aqs=chrome..69i57j0i512l3j69i60l3j69i65.7146j0j7&sourceid=chrome&ie=UTF-8#lrd=0x47e66f6573ae99a1:0x59f06510e9fe4524,1,,," target="_blank"><FontAwesomeIcon className="icon-details" icon={faExternalLinkAlt} color="#AFAFAF" />Google</a> {i18n.t('and')} <a href="https://avis2.avis-verifies.com/leaveareview_v2.php?idWebsite=e1b8ca81-1250-e974-a950-bfa4d209ae22" target="_blank"><FontAwesomeIcon className="icon-details" icon={faExternalLinkAlt} color="#AFAFAF" />Avis Vérifiés</a>
            </p>
             <Summary data={data} type={"midterm"} />
        </>
      )
    } else {
      // 3.2 BIF NON REALISE
      return (
        <>
          <h3>{i18n.t('views.trainings.chart.midterm_overview')}</h3>
            <p className="">
              <a href={data?.bpt_link} target="_blank">
                { i18n.t('views.trainings.chart.book_midterm')}
              </a>
            </p>
            <p className="bloc-divider">
              {i18n.t('views.trainings.chart.five_star')} <a href="https://www.google.com/search?q=cercle+des+langues+google&rlz=1C1CHBF_frFR924FR925&oq=cercle+des+langues+google&aqs=chrome..69i57j0i512l3j69i60l3j69i65.7146j0j7&sourceid=chrome&ie=UTF-8#lrd=0x47e66f6573ae99a1:0x59f06510e9fe4524,1,,," target="_blank"><FontAwesomeIcon className="icon-details" icon={faExternalLinkAlt} color="#AFAFAF" />Google</a> {i18n.t('and')} <a href="https://avis2.avis-verifies.com/leaveareview_v2.php?idWebsite=e1b8ca81-1250-e974-a950-bfa4d209ae22" target="_blank"><FontAwesomeIcon className="icon-details" icon={faExternalLinkAlt} color="#AFAFAF" />Avis Vérifiés</a>
            </p>
            <p className="bloc-divider"><FontAwesomeIcon className="icon-details" icon={faUserFriends} color="#AFAFAF" /> <a target="_blank" href="https://www.cercledeslangues.com/parrainage-eleves">
            { i18n.t('views.trainings.chart.sponsor')}
          </a></p>
        </>
      )
    }
  }

  return (
    <>
      {(() => {
        if (data?.test_type && data?.test_type?.toLowerCase() != "sans test" && data?.test_type != "N/A") {

          // 2.1.1 CAS TEST REALISE
          if (data.test_done) {
            let attestation = data?.documents.filter(t => { return t?.document_type == "certifying_attestation" })
            let link = attestation[0] ? attestation[0].url : "#"
            let showLink = attestation[0] ? "" : "hidden"
            let score = data?.test_type?.includes("360") ? data?.test_score_360 : data?.test_level_total
            return (
              <div className={`detail-box ${responsiveOne && "responsive"} `}>
                <div className={`informations ${responsiveOne && "responsive"} `}>
                <h3>{i18n.t('views.trainings.chart.midterm')}</h3>

                  <div className="flex-container">
                    <div className="sub-container">
                      <h3>{i18n.t('views.trainings.chart.certifying_evaluation')}</h3>
                      <p>
                        {i18n.t('views.trainings.chart.evaluation_date_passed', {test: data?.test_type, date: data?.test_date})}
                      </p>
                      <p className={showLink}><FontAwesomeIcon className="icon-details" icon={faDownload} color="#AFAFAF" /> <a target="_blank" href={link} className="black-link">
                      {i18n.t('views.trainings.chart.passing_attestation')}
                      </a></p>
                    </div>
                     <TestWidget score={score} />
                  </div>
                  <TestResult data={data}/>
                  {/* {renderTestFeedback(data, false)} */}
                  {renderBilanIntermediate()}
                </div>
              </div>

            )
          }
          // 2.1.2 CAS TEST NON REALISE
          else {
            let link_bloc
            if (data?.test_type?.toLowerCase().includes("ingua") || data?.test_type?.toLowerCase().includes("ipplet")) {
              link_bloc = (

                <div className="detail-box">
                  <div className="informations">
                  <h3>{i18n.t('views.trainings.chart.midterm')}</h3>

                    <p className="bloc-divider"><a target="_blank" className="black-link" href={data?.test_link}><FontAwesomeIcon className="icon-details" icon={faExternalLinkAlt} color="#AFAFAF" />
                    {i18n.t("views.trainings.chart.evaluation_link")}
                    </a></p>
                    <p>
                    {i18n.t("views.trainings.chart.test_token")}
                       : {data?.test_token}</p>
                  </div>
                </div>
              )
            }
            return (
              <div className="detail-box">
                <div className="informations">
                <h3>{i18n.t('views.trainings.chart.midterm')}</h3>

                  <h3>
                    {i18n.t('views.trainings.chart.certifying_evaluation')}
                  </h3>

                  <p>
                    {i18n.t('views.trainings.chart.evaluation_date', {test: data?.test_type, date: data?.planned_test_date})}
                  </p>

                  {link_bloc}
                  {renderBilanIntermediate()}
                </div></div>
            )
          }
          // 2.2 CAS PAS DE TEST
        } else {
          return (
            <div className="detail-box">
              <div className="informations">
              <h3>{i18n.t('views.trainings.chart.midterm')}</h3>
              <h3>{i18n.t('views.trainings.chart.certifying_evaluation')}</h3>
              <p>{ ReactHtmlParser (i18n.t('views.trainings.chart.no_test'))}</p>
              {renderBilanIntermediate()}
              </div>
            </div>
          )
        }
      })()}
    </>
  );

}
