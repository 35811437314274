import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThLarge, faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import i18n from '../../../i18n'


const StudentNav = ({ setShowNavbar, showNavbar, setOpenNavbar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.user.userData)
  const loading = useSelector(state => state.lessons.loading)
  const dispatch = useDispatch()
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const path = location.pathname

  //${loading && 'disabled'}`} to={loading && '/lessons'}>

  const handleResponsiveNavbar = () => {
    if (responsiveOne) {

      setShowNavbar(!showNavbar)
      dispatch(setOpenNavbar(!showNavbar))
    }
  }

  return (
    <div className="nav-list">
      <Link className={`nav-el`} to={'/s_planning'}>
        <div className={`icon ${(location.pathname == '/s_planning' || location.pathname == '/s_lessons') && 'active'}`}>
          <span className="nav-icon planning"></span>
          {i18n.t('navigation.student.lessons')}
        </div>
      </Link>

      {/* sublink */}
      {(location.pathname == '/s_lessons' || location.pathname == "/s_planning") &&
        <div className="sublink-bloc">
          <Link className="nav-el" to={'/s_planning'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/s_planning' ? 'active' : ''}`}>
              {i18n.t('navigation.student.agenda')}
            </div>
          </Link>
          <Link className={`nav-el ${loading && 'disabled loading'}`} to={!loading && '/s_lessons'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/s_lessons' ? 'active' : ''}`}>
              {i18n.t('navigation.student.list')}
            </div>
          </Link>
        </div>
      }

      <Link className={`nav-el ${!user.access_elearning && 'disabled'}`} to={user.access_elearning && '/elearnings_card_view'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname === '/elearnings_card_view' || location.pathname === "/elearnings_card_view")
            ? 'active' : ''} `}>
          <span className="nav-icon elearning"></span>
          {i18n.t('navigation.student.elearnings')}
        </div>
      </Link>

      <Link className={`nav-el`} to={'/collective_planning'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname == '/collective_lessons' || location.pathname == "/collective_planning") && 'active'}`}>
          <span className="nav-icon collective_lessons"></span>
          {i18n.t('navigation.student.collective_lessons')}
        </div>
      </Link>

      {/* sublink */}
      {(location.pathname == '/collective_lessons' || location.pathname == "/collective_planning") &&
        <div className="sublink-bloc">
          <Link className="nav-el" to={'/collective_planning'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/collective_planning' && 'active'}`}>
              {i18n.t('navigation.student.agenda')}
            </div>
          </Link>
          <Link className={`nav-el ${loading && 'disabled loading'}`} to={'/collective_lessons'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/collective_lessons' && 'active'}`}>
              {i18n.t('navigation.student.list')}
            </div>
          </Link>
        </div>
      }

      <Link className={`nav-el ${!user.access_conference && 'disabled'}`} to={user.access_conference && '/conferences'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/conferences' && 'active'} `}>
          <span className="nav-icon confs"></span>
          {i18n.t('navigation.student.conferences')}
        </div>
      </Link>



      <Link className="nav-el" to={`/trainings/${user.training}`}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname.includes('/trainings') && 'active'}`}>
          <span className="nav-icon my_trainings"></span>
          {i18n.t('navigation.student.trainings')}
        </div>
      </Link>

      {/* sublink */}
      {(location.pathname.includes('/trainings') && user.other_trainings_ids.length > 1) &&
        <div onClick={() => handleResponsiveNavbar()} className="sublink-bloc">

          {user.other_trainings_ids.map((training, index) => {
            return (
              <Link key={index} className="nav-el" to={`/trainings/${training[0]}`}>
                <div className={`icon ${location.pathname.includes(training[0]) ? 'active' : ''}`}>
                  {training[1]}
                </div>
              </Link>
            )
          })}


        </div>
      }

      <Link onClick={() => handleResponsiveNavbar()} className={`nav-el`} to={'/student'}>
        <div className={`icon ${location.pathname == '/student' && 'active'}`}>
          <span className="nav-icon profile"></span>
          {i18n.t('navigation.student.profile')}
        </div>
      </Link>

    </div>
  )
}

export default StudentNav;
