import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from '../../../i18n'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TeacherNav = ({ setShowNavbar, showNavbar, setOpenNavbar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.user.userData)
  const dispatch = useDispatch()
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  const handleResponsiveNavbar = () => {
    if (responsiveOne) {

      setShowNavbar(!showNavbar)
      dispatch(setOpenNavbar(!showNavbar))
    }

  }

  const warningForLateInvoice = () => {
    return <OverlayTrigger
      placement={"bottom"}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id='button-tooltip'>{i18n.t(`views.invoices.late_invoice`)}</Tooltip>}
    >
      <div>
      <FontAwesomeIcon className="exclamation-mark" color="#BE2522" icon={faCircleExclamation} />
      </div>
    </OverlayTrigger>
  }


  return (
    <div className="nav-list">

      <Link className="nav-el" to={'/t_planning'}>
        <div className={`icon ${(location.pathname == '/t_planning' || location.pathname == '/t_lessons' || location?.pathname?.includes('t_lessons')) && 'active'}`}>
          <span className="nav-icon planning"></span>
          {i18n.t('navigation.teacher.lessons')}
        </div>
      </Link>

      {/* sublink */}
      {(location.pathname == '/t_lessons' || location.pathname == "/t_planning" || location.pathname == "/week_type" || location.pathname?.includes('/t_lessons/')) &&
        <div className="sublink-bloc">
          <Link className="nav-el" to={'/t_planning'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/t_planning' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.agenda')}
            </div>
          </Link>
          <Link className="nav-el" to={'/t_lessons'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname == '/t_lessons' || location.pathname?.includes('/t_lessons/')) ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.lessons')}
            </div>
          </Link>
          <Link className="nav-el" to={'/week_type'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/week_type' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.week_type')}
            </div>
          </Link>
        </div>
      }

      <Link className={`nav-el`} to={'/t_trainings'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/t_trainings' && 'active'}`}>
          <span className="nav-icon students"></span>
          {i18n.t('navigation.teacher.trainings')}
        </div>
      </Link>


      <Link className={`nav-el`} to={'https://elearning.cercledeslangues.com/'} target="_href" >
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname === '/elearnings_card_view' || location.pathname === "/elearnings_card_view")
          ? 'active' : ''} `}>
          <span className="nav-icon elearning"></span>
          {i18n.t('navigation.student.elearnings')}
        </div>
      </Link>


      <Link className={`nav-el`} to={'/t_tickets/availability'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname == '/t_tickets/recredit' || location.pathname == '/t_tickets/availability' || location.pathname == '/t_tickets/students') && 'active'}`}>
          <span className="nav-icon elearning"></span>
          {i18n.t('navigation.teacher.tickets')}
        </div>
      </Link>

      {(location.pathname == '/t_tickets/recredit' || location.pathname == '/t_tickets/availability' || location.pathname == '/t_tickets/students') &&
        <div className="sublink-bloc">
          <Link className="nav-el" to={'/t_tickets/availability'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/t_tickets/availability' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.availabilities')}
            </div>
          </Link>
          <Link className="nav-el" to={'/t_tickets/students'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/t_tickets/students' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.trainings')}
            </div>
          </Link>
          <Link className="nav-el" to={'/t_tickets/recredit'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/t_tickets/recredit' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.recredits')}
            </div>
          </Link>
        </div>
      }

      <Link className={`nav-el`} to={'/teacher'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/teacher' && 'active'}`}>
          <span className="nav-icon profile"></span>
          {i18n.t('navigation.teacher.profile')}
        </div>
      </Link>

      <Link className={`nav-el`} to={'/invoices'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/invoices' && 'active'}`}>
          <span className="nav-icon invoices"></span>
          {i18n.t('navigation.teacher.invoices')}



          {user?.teacher_has_late_invoice && warningForLateInvoice()}

        </div>
      </Link>

    </div>
  )
}

export default TeacherNav;
