import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getStateForm,
  manageLesson,
  createLesson,
  cancelLesson,
  createCollectiveLesson,
  adminCollectiveBooking,
  teacherCancelRequest,
  adminCollectiveCanceling
} from "../lessonsSlice";
import { getAvStateForm, createAvailability, deleteAvailability, createSlot, closeSlot } from "../../availabilities/availabilitiesSlice";
import { Button } from 'react-bootstrap';
import Select from 'react-select'
import { beSubscribeCourseLesson } from "../../cours-collectives/coursCollectivesSlice";
import i18n from '../../../i18n'
import ReactHtmlParser from 'react-html-parser';


const StateManagement = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  const search = location.search;
  const wantedState = new URLSearchParams(search).get('state');
  const lessonId = new URLSearchParams(search).get('l');
  const training = new URLSearchParams(search).get('t');
  const ticketId = new URLSearchParams(search).get('ti');
  const student = new URLSearchParams(search).get('student');
  const teacher = new URLSearchParams(search).get('teacher');
  const step = new URLSearchParams(search).get('step');
  const availabilityId = new URLSearchParams(search).get('a');
  const slot = new URLSearchParams(search).get('slot');
  const slotId = new URLSearchParams(search).get('s');
  const [note, setNote] = useState(null)
  const [document, setDocument] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const { store } = useContext(ReactReduxContext)
  const state = store.getState()
  const [form, setForm] = useState()
  const basePath = useLocation().pathname;
  const { profilable } = useSelector((state) => state.user.userData)
  const [recreditSelect, setRecreditSelect] = useState(false)
  const [requestReason, setRequestReason] = useState(null)

  const user = useSelector(state => state.user.userData);
  const [to_state, setToState] = useState((wantedState == "booking" && user.role == "Teacher") ? "closing" : wantedState)

  const [disabledBtn, setDisabledBtn] = useState()

  const [params, setParams] = useState({})

  useEffect(() => {
    if (lessonId) {
      dispatch(getStateForm({ "id": lessonId, "wanted_state": wantedState, "student": student })).then(() => {
        setTimeout(() => {
          const state = store.getState();
          setForm(state.lessons.stateForm);
        }, 100);
      })
    }
    if (wantedState == 'recredit') {
      setRecreditSelect(true)
    }
  }, [lessonId]);


  useEffect(() => {
    if (availabilityId) {
      if (availabilityId.includes('start')) {
        const av = JSON.parse(availabilityId)
        if (wantedState == 'admin_new_av') {
          //const state = store.getState();
          const teacher = state.filters.filtersLesson.teachers
          if (teacher) {
            if (step == "collective") {
              const form_title = `Vous êtes sur le point d'ouvrir un nouveau créneau pour un cours collectif le ${formatDate(av.start)}`
              const form_content = "new_collective"
              const form_submit = "Confirmer"
              setToState("course_collective_creation")
              setForm({ form_title: form_title, form_submit: form_submit, form_content: form_content })
            } else {
              const form_title = `Vous allez ouvrir une disponibilité pour ${teacher.label}. Pour quel type de cours ?`
              const choices = [{ slug: 'personal', label: "Cours particulier" }, { slug: 'collective', label: "Cours collectif" }]
              setForm({ form_title: form_title, hasStep: true, choices: choices })
            }
          }
        } else {
          const form_title = i18n.t('activerecord.attributes.availability.state_form.title.create', {
            date: formatDateAvailability(av.start, av.end).firstDateFormatted,
            hoursFirstDate: formatDateAvailability(av.start, av.end).firstDateFormattedHours,
            hoursSecondDate: formatDateAvailability(av.start, av.end).secondDateFormatted
        } )
          const form_submit = i18n.t('validate')
          setForm({ form_title: form_title, form_submit: form_submit })
        }
      } else {
        dispatch(getAvStateForm({ "id": availabilityId, "wanted_state": wantedState, "student": student })).then(() => {
          setTimeout(() => {
            const state = store.getState();
            setForm(state.availabilities.avStateForm)
          }, 100);
        })
      }
    } else if (slot) {
      const cleanSlot = JSON.parse(slot)
      const form_title = i18n.t('activerecord.attributes.availability.state_form.title.recurring_availability',{
        date: formatDateAvailability(cleanSlot.start, cleanSlot.end).firstDateFormatted,
        hoursFirstDate: formatDateAvailability(cleanSlot.start, cleanSlot.end).firstDateFormattedHours,
        hoursSecondDate: formatDateAvailability(cleanSlot.start, cleanSlot.end).secondDateFormatted
        })
      const form_submit = i18n.t('validate')
      setForm({ form_title: form_title, form_submit: form_submit })
    } else if (slotId) {
      const form_title =  i18n.t('activerecord.attributes.availability.state_form.title.closing')
      const form_submit = i18n.t('validate')
      const notice = i18n.t('activerecord.attributes.availability.state_form.text.closing')
      setForm({ form_title: form_title, form_submit: form_submit, form_text: notice, required_note: true })
    }
  }, [availabilityId, step, slot]);


  useEffect(() => {

    if ((note == null || note == "" || note?.trim()?.length===0) && form?.required_note) {
      setDisabled(true)
    } else if (document == null && form?.required_document) {
      setDisabled(true)
    }
    else {
      setDisabled(false)
    }
  }, [form, note, document]);


  const renderNotice = (notice) => {
    if (notice) {
      return <p className="warning" style={{ textAlign: "center" }}>{notice}</p>
    }
  }

  const renderText = (text) => {
    if (text) {
      return <p>{text}</p>
    }
  }

  const renderDocument = (required_document) => {
    if (required_document) {
      return (
        <div id="level" className="modal-form-line">
          <span>
            {i18n.t('form.proof')}

          </span>
          <input
            id="files"
            type="file"
            style={{ visibility: "hidden", width: '80%' }}
            onChange={() => { handleFileUpload() }}
          />
          <label htmlFor="files" style={{ background: "#E8E8E8", padding: "2px", cursor: "pointer", width: "60%", border: "1px solid lightgray", borderRadius: "5px", textAlign: "center", marginTop: '6px', color: 'grey' }}>
            {document ? document.name : 'Télécharger un document'}
          </label>
        </div>
      )
    }
  }

  const handleChange = () => {
    setNote(event.target.value)
  }

  const handleFileUpload = () => {
    setDocument(event.target.files[0])
  }

  const renderNote = (required_note) => {
    if (required_note) {
      return <input autocomplete="off" type="text" className="textarea-modal" name="name" onChange={() => { handleChange() }} style={{ width: '100%', minHeight: 60, marginBottom: 30, marginTop: 30 }} placeholder={`Justification ...`} />
    }
  }

  const dispatcher = {
    "booking": () => dispatch(createLesson({ availability_id: availabilityId, student_id: null })),
    "admin_single_booking": () => dispatch(createLesson({ availability_id: availabilityId, student_id: student })),

    "new_av": () => dispatch(createAvailability({ availability: { event: JSON.parse(availabilityId) } })),
    "new_slot": () => dispatch(createSlot({ slot: JSON.parse(slot), teacher: teacher })),
    "admin_new_av": () => dispatch(createAvailability({ availability: { event: JSON.parse(availabilityId), teacherId: state.filters.filtersLesson.teachers.value } })),

    "closing": () => dispatch(deleteAvailability({ availability_id: availabilityId, ticket: { note: note, app_document: document } })),

    "passed": (to_state) => dispatch(manageLesson({ lesson_id: lessonId, to_state: to_state })),
    "recredit": (to_state) => dispatch(manageLesson({ lesson_id: lessonId, to_state: to_state, ticket: { note: note, app_document: document, requestReason: requestReason.value } })), // should generate a ticket
    "cancel-recredit": (to_state) => dispatch(manageLesson({ lesson_id: lessonId, to_state: to_state })), // should delete tickets
    "noshow": (to_state) => dispatch(manageLesson({ lesson_id: lessonId, to_state: to_state })),
    "rebook": () => dispatch(manageLesson({ lesson_id: lessonId, to_state: "booked" })),
    "canceled": () => dispatch(cancelLesson({ lesson_id: lessonId, ticket: { note: note } })),
    "admin_canceled": () => dispatch(cancelLesson({ lesson_id: lessonId })),
    "canceled_by_teacher": () => dispatch(cancelLesson({ lesson_id: lessonId, to_state: "canceled_by_teacher", ticket: { note: note } })),
    "admin_canceled_by_teacher": () => dispatch(cancelLesson({ lesson_id: lessonId, to_state: "canceled_by_teacher" })),
    "late_cancelation": () => dispatch(cancelLesson({ lesson_id: lessonId, ticket: { note: note } })),
    "ask_for_cancel": () => dispatch(teacherCancelRequest({ lesson_id: lessonId, student_id: student, teacher_id: state.filters.filtersLesson.teachers?.value })),

    "course_collective_booking": () => dispatch(beSubscribeCourseLesson({ lesson_id: lessonId, student_id: profilable })),
    "course_collective_creation": () => dispatch(createCollectiveLesson({ availability: { event: JSON.parse(availabilityId) }, params: params, teacherId: state.filters.filtersLesson.teachers?.value })),

    "admin_collective_booking": () => dispatch(adminCollectiveBooking({ lesson_id: lessonId, student_id: student })),
    "admin_collective_canceling": () => dispatch(adminCollectiveCanceling({ attendance_id: student })),
    "close_slot": () => dispatch(closeSlot({ slot: slotId, note: note })),

  }



  const mapActions = (to_state) => {
    navigate(basePath)
    dispatcher[to_state](to_state)
  }

  const formatDate = (date) => {

    let r = date.replace(' ', '+')

    let d = new Date(r),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear(),
      hours = '' + d.getHours(),
      minutes = '' + d.getMinutes()

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (minutes.length < 2)
      minutes = '0' + minutes;

    return `${[day, month, year].join('-')} - ${hours}:${minutes}`;
  }

  const formatDateAvailability = (firstDate, secondDate) => {

    let r = firstDate.replace(' ', '+')
    let s = secondDate.replace(' ', '+')

    let d = new Date(r),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear(),
      hours = '' + d.getHours(),
      minutes = '' + d.getMinutes()

    let se = new Date(s),
      hoursSecond = '' + se.getHours(),
      minutesSecond = '' + se.getMinutes()

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (minutes.length < 2)
      minutes = '0' + minutes;
    if (minutesSecond.length < 2)
      minutesSecond = '0' + minutesSecond;

    const firstDateFormatted = `${day}-${month}-${year}`;
    const firstDateFormattedHours = `${hours}:${minutes}`;
    const secondDateFormatted = `${hoursSecond}:${minutesSecond}`;

    return {
        firstDateFormatted,
        firstDateFormattedHours,
        secondDateFormatted
    };

    return `${[day, month, year].join('-')} de ${hours}:${minutes} à ${hoursSecond}:${minutesSecond}`;
  }

  const appendToLocation = (slug) => {
    if (slug == "personal") {
      mapActions(to_state)
    } else {
      const newLocation = location.pathname + location.search + `&step=${slug}`
      navigate(newLocation)
    }
  }

  const onSelectChange = value => {
    let id = event.target.closest(".modal-form-line").id
    setParams({ ...params, [id]: value.value })
  }

  const onChange = () => {
    setParams({ ...params, theme: event.target.value })
  }

  const selectRecreditValue = value => {
    setRequestReason(value)
  }

  const renderSelect = (recreditSelect) => {
    if (recreditSelect) {

      const options = [
        { value: 'medical_emergency', label: 'Urgence médicale' },
        { value: 'technical_problem', label: 'Problème technique' },

      ]

      return (
        <div id="level" className="modal-form-line">
          <div>{i18n.t("views.lessons.recredit_reason")}</div>
          <Select placeholder='Sélectionner...' options={options} value={requestReason} onChange={selectRecreditValue} />
        </div>
      )
    }
  }

  const renderContent = (content) => {
    if (form?.form_content == "new_collective") {

      const levelOptions = [
        { value: "A0", label: "A0" },
        { value: "A1", label: "A1" },
        { value: "A2", label: "A2" },
        { value: "B1", label: "B1" },
        { value: "B2", label: "B2" },
        { value: "C1", label: "C1" }
      ]

      const englishTypeOptions = [
        { value: 'Everyday', label: 'Everyday' },
        { value: 'Grammar', label: 'Grammar' },
        { value: 'Business English', label: 'Business' },
        { value: 'Test preparation', label: 'Test preparation' }
      ]

      return (
        <>
          <div id="level" className="modal-form-line">
            <div>Niveau du cours</div>
            <Select placeholder='Sélectionner...' options={levelOptions} onChange={onSelectChange} />
          </div>
          <div id="english_type" className="modal-form-line">
            <div>Domaine linguistique</div>
            <Select placeholder='Sélectionner...' options={englishTypeOptions} onChange={onSelectChange} />
          </div>
          <div id="theme" className="modal-form-line search-line">
            <div>Thème du cours</div>
            <input htmlFor="theme_text" onChange={onChange} value={params.theme}></input>
          </div>
        </>
      )
    }
  }

  const renderSubmit = () => {
    if (form?.hasStep) {
      return (
        <div className="submit-container">
          {
            form?.choices.map((choice, index) => {
              return <Button key={index} action="submit" onClick={() => appendToLocation(choice.slug)} className={`btn ${index == 1 ? "btn-primary" : "btn-secondary"}`}>{choice.label}</Button>
            })
          }
        </div>
      )
    } else {
      return <Button action="submit" onClick={() => mapActions(to_state)} disabled={disabled || disabledBtn} className={`btn btn-modal ${(disabledBtn && training) && "disabledBtn"} `}>{form?.form_submit}</Button>
    }
  }


  if (form) {
    return (
      <>
        <h3>{ReactHtmlParser(form?.form_title)}</h3>
        {renderNotice(ReactHtmlParser(form?.form_notice))}
        {renderText(ReactHtmlParser(form?.form_text))}
        {renderNote(form?.required_note)}
        {renderSelect(recreditSelect)}
        {renderDocument(form?.required_document)}
        {renderContent(form?.form_content)}
        {renderSubmit()}
      </>
    )
  } else {
    return (
      <p>Loading ...</p>
    )
  }

}

export default StateManagement;
