import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLesson, postReview, setDisableTextEditor, validateReview } from "./lessonsSlice";
import Status from "./components/status";
import RichEditor from './components/richEditor';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ReviewPartTitle from "./components/reviewPartTitle";
import Modal from '../toolbox/modal';
import ElearningModales from '../e-learning/elearnings_modales';
import i18n from '../../i18n'
import JoditEditor from "jodit-react";



const Lesson = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation()
  const search = useLocation().search;
  const lessonId = new URLSearchParams(search).get('l') || location.pathname.split("/")[2];
  const user = useSelector(state => state.user.userData)
  const lesson = useSelector(state => state.lessons.lessonShow)
  const { store } = useContext(ReactReduxContext)
  const editable = lesson?.state == "passed" && user.role == "Teacher"
  const panelState = new URLSearchParams(search).get('pm');
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const [valid, setValid] = useState(false)
  const [review, setReview] = useState({})
  const [sendEmail, setSendEmail] = useState(false)
  const [attendings, setAttendings] = useState([])
  const modalRef = useRef(null);
  const elearningsRef = useRef(null);
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    lessonId && dispatch(getLesson(lessonId))
  }, []);

  useEffect(() => {
    const state = store.getState();
    const lesson = state.lessons.lessonShow
    lesson.id == lessonId && setReview(lesson?.review)
  }, [lesson]);

  useEffect(() => {
    // setCompareVals

    setValid(
      (lesson.student_name === "collective" && attendings.length == 0) ||
      (review?.goal?.length >= 40 && review?.theme?.length >= 5 && review?.teacher_note?.length >= 200 && review?.exercices?.length >= 10)
    )
  }, [review, attendings]);

  const onChange = (event, attr) => {
    const value = typeof event == "string" ? event : event.target.value;
    setReview({ ...review, [attr]: value})
    setReview({ ...review, [attr]: value})
    setSendEmail(valid ? sendEmail : false)
  }

  const onBlur = (event, attr) => {
    const value = typeof event == "string" ? event : event.target.value;
    setReview({ ...review, [attr]: value})
    let compared = JSON.stringify(lesson.review) === JSON.stringify({ ...review, [attr]: value})
    if (!compared) {
      setLoading(true);
      dispatch(postReview({ review: { ...review, [attr]: value}, lesson_id: lessonId }))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
     }
  }

  const showModal = () => {
    modalRef.current?.onShow()
  }

  const closeConfirm = () => {
    dispatch(validateReview({ review: review, attendings: attendings }))
    modalRef.current?.onHide()
  }

  const onSubmit = (event) => {
    event.preventDefault();
    showModal()
  }

  const bodyConfirm = () => {
    if (lesson.student_name !== "collective") {
      return (
        <>
          <h3>{i18n.t(`views.lessons.review.attendances_validation.warning`)}</h3>
        </>
      )
    }
    if (attendings?.length == 1) {
      return (
        <>
          <h3>{i18n.t(`views.lessons.review.attendances_validation.single_attendance`)}</h3>
          <h3>{i18n.t(`views.lessons.review.attendances_validation.warning`)}</h3>
        </>
      )
    } else if (attendings?.length > 1) {
      return (
        <>
          <h3>{i18n.t(`views.lessons.review.attendances_validation.multiple_attendance`)}</h3>
          <h3>{i18n.t(`views.lessons.review.attendances_validation.warning`)}</h3>
        </>
      )
    } else if (attendings?.length == 0) {
      if (lesson?.attendances?.length > 1) {
        return (
          <>
            <h3>{i18n.t(`views.lessons.review.attendances_validation.noshow`)}</h3>
            <h3>{i18n.t(`views.lessons.review.attendances_validation.warning`)}</h3>
          </>
        )
      }
    }
  }

  const openElearnings = () => {
    elearningsRef.current?.onShow()
  }

  const closeElearningModale = () => {
    closeAdditional()
    elearningsRef.current?.onHide()
  }

  const closeAdditional = () => {
    dispatch(getLesson(lessonId))
  }

  const renderFormHeader = () => {
    if (editable) {
      let conditions = `
            Make sure to validate the following conditions
            - Thème : 5 char.  ${review?.theme?.length >= 5 ? "OK" : "to complete"}
            - Objectif : 40 car. ${review?.goal?.length >= 40 ? "OK" : "to complete"}
            - Résumé : 200 car. ${review?.teacher_note?.length >= 200 ? "OK" : "to complete"}
            - Exercice Elearning : at least 1 attached ? ${review?.exercices?.length >= 10 ? "OK" : "to complete"}
            `
      return (
        <div className="submit-form">
          <Button
            type="submit"
            disabled={(!valid) && "disabled"}
            onClick={onSubmit}
          >
            {i18n.t(`validate`)}
          </Button>
          <div className="line form-check">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>{conditions}</Tooltip>}
            >
              <FontAwesomeIcon className="icon-details" icon={faInfoCircle} color="#1ABC9C" />
            </OverlayTrigger>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  const close = () => {
    let route

    if (user?.role === 'Teacher') {
      route = location?.pathname?.includes('t_trainings') ? `/t_trainings?l=${lessonId}&pm=s` : `/t_lessons?l=${lessonId}&pm=s`
    } else if (user?.role === 'Student') {
      route = `/s_lessons?l=${lessonId}&pm=s`
    } else if (user?.role === 'Admin') {
      route = location?.pathname?.includes('a_trainings') ? `/a_trainings?l=${lessonId}&pm=s` : `/a_lessons?l=${lessonId}&pm=s`
    }
    else{
      route = location?.pathname?.includes('h_trainings') ? `/h_trainings?l=${lessonId}&pm=s` : `/h_lessons?l=${lessonId}&pm=s`

    }

    navigate(route)
  };

  const handleStudentCheck = () => {
    //debugger
    let attendingInputs = document.getElementsByClassName("student-check-input")
    let attendingStudents = []
    for (var i = 0; i < attendingInputs.length; i++) {
      attendingInputs[i].checked ? attendingStudents.push(attendingInputs[i].id) : false
    }
    setAttendings(attendingStudents)
  }

  const renderExercices = () => {
    if (review && review.exercices?.length > 0) {
      return (
        JSON.parse(review.exercices)?.map((a, idx) => {
          return (
            <div key={idx}>
              <a href={a.exercice_link} target="blank">{a.name}</a>
              {/* <span> - {states[a.state]}</span> */}
            </div>
          )
        })
      )
    }
  }

  const renderAttendanceManagement = () => {
    const state = store.getState();
    const lesson = state.lessons.lessonShow
    if (lesson && lesson.id == lessonId && lesson.attendances?.length > 0 && lesson.student_name === "collective") {
      return (
        <div className="lesson-bloc">
          <div className="review-part-title">
            <h4>{i18n.t(`views.lessons.review.attendances`)} :</h4>
          </div>
          <ul className="student-list">
            {
              lesson.attendances.map((student, idx) => {
                return (
                  <li className="line" key={idx}>
                    <label htmlFor="student">{student["name"]}</label>
                    <input
                      className="boolean optional student-check-input"
                      type="checkbox"
                      defaultChecked={student.state == "show"}
                      name="student"
                      id={student["id"]}
                      disabled={!editable ? "disabled" : ""}
                      onChange={handleStudentCheck} />
                  </li>
                )
              })
            }
          </ul>
        </div>
      )
    }
  }

  if (lesson) {
    return (
      <>
        {
          (!location?.search?.includes('pm') || (location?.pathname?.includes('trainings') && panelState==='f'  ) ||  (location?.pathname?.includes('lessons') && panelState==='f'  ) ) ? <div className="main-content lessonShow" >
            <div className={` page-container ${(openNavbar || responsiveFilter || (responsiveOne && panelState == 's')) && 'responsive-hidden'} ${panelState === 's' ? 'sided' : ''}`} >
              <div className="actions">
                <div onClick={() => close()} className="icon close-icon"></div>
              </div>
              <div className="lesson">
                <div className="lesson-header">
                  <h3>{i18n.t('views.lessons.lesson_with')} {lesson.student_name}</h3>
                  <h4>{lesson.date} à {lesson.hour}</h4>
                  <Status status={lesson.state} />
                </div>

                {renderFormHeader()}
                {renderAttendanceManagement()}

                <div className="lesson-bloc">
                  {ReviewPartTitle('theme', editable)}

                  <RichEditor key={lesson.id} attr={'theme'} value={review?.theme} onChange={onChange} onBlur={onBlur} editable={editable} simple loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('goal', editable)}
                  <RichEditor key={lesson.id} attr={'goal'} value={review?.goal} onChange={onChange} onBlur={onBlur} editable={editable} simple loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('teacher_note', editable)}
                  <RichEditor key={lesson.id} attr={'teacher_note'} value={review?.teacher_note} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('elearning', editable)}
                  <div className="exercice-list">
                    {renderExercices()}
                  </div>
                  {editable && <div className="green-link" onClick={() => { openElearnings() }}>{i18n.t(`views.lessons.review.attach_elearning`)}</div>}
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('home_work', editable)}
                  <RichEditor key={lesson.id} attr={'home_work'} value={review?.home_work} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('vocabulary', editable)}

                  <RichEditor key={lesson.id} attr={'vocabulary'} value={review?.vocabulary} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('grammar', editable)}
                  <RichEditor key={lesson.id} attr={'grammar'} value={review?.grammar} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('phonetic', editable)}
                  <RichEditor key={lesson.id} attr={'phonetic'} value={review?.phonetic} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('error', editable)}
                  <RichEditor key={lesson.id} attr={'error'} value={review?.error} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                {/* <div className="lesson-bloc">
                      <h4>Enregistrement vidéo</h4>
                      {lesson?.video}
                  </div> */}
              </div>
            </div>
          </div> :
            <div className={` page-container ${(panelState == 's' && responsiveOne) && "responsive-panel"}`} >
              <div className="lesson">
                <div className="lesson-header">
                  <h3>{i18n.t('views.lessons.lesson_with')} {lesson.student_name}</h3>
                  <h4>{lesson.date} à {lesson.hour}</h4>
                  <Status status={lesson.state} />
                </div>

                {renderFormHeader()}
                {renderAttendanceManagement()}

                <div className="lesson-bloc">
                  {ReviewPartTitle('theme', editable)}
                  <RichEditor key={lesson.id} attr={'theme'} value={review?.theme} onChange={onChange} onBlur={onBlur} editable={editable} simple loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('goal', editable)}
                  <RichEditor key={lesson.id} attr={'goal'} value={review?.goal} onChange={onChange} onBlur={onBlur} editable={editable} simple loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('teacher_note', editable)}
                  <RichEditor key={lesson.id} attr={'teacher_note'} value={review?.teacher_note} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('elearning', editable)}
                  <div className="exercice-list">
                    {renderExercices()}
                  </div>
                  {editable && <div className="green-link" onClick={() => { openElearnings() }}>{i18n.t(`views.lessons.review.attach_elearning`)}</div>}
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('home_work', editable)}
                  <RichEditor key={lesson.id} attr={'home_work'} value={review?.home_work} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('vocabulary', editable)}
                  <RichEditor key={lesson.id} attr={'vocabulary'} value={review?.vocabulary} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('grammar', editable)}
                  <RichEditor key={lesson.id} attr={'grammar'} value={review?.grammar} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('phonetic', editable)}
                  <RichEditor key={lesson.id} attr={'phonetic'} value={review?.phonetic} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                <div className="lesson-bloc">
                  {ReviewPartTitle('error', editable)}
                  <RichEditor key={lesson.id} attr={'error'} value={review?.error} onChange={onChange} onBlur={onBlur} editable={editable} loading={loading} />
                </div>
                {/* <div className="lesson-bloc">
                    <h4>Enregistrement vidéo</h4>
                    {lesson?.video}
                </div> */}
              </div>
            </div>
        }


        <Modal ref={modalRef} closeAdditional={closeAdditional}>
          {bodyConfirm()}
          <Button onClick={() => { closeConfirm() }}>{i18n.t(`confirm`)}</Button>
        </ Modal>
        <Modal ref={elearningsRef} closeAdditional={closeAdditional}>
          <ElearningModales closeElearningModale={closeElearningModale} />
        </ Modal>
      </>
    )
  }
  // to update with data from controller & translation
  return (
    <div className="lesson">
      {i18n.t(`errors.messages`)}
    </div>
  )

};

export default Lesson;
