import React, { Component } from 'react'
import {Button, Container, Row, Col, Collapse} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMale, faDownload, faExternalLinkAlt, faChevronUp, faChevronDown, faUserFriends, faRedo } from '@fortawesome/free-solid-svg-icons';
import openArrow from "../../images/icons/openArrow.svg";
import closeArrow from "../../images/icons/closeArrow.svg";
import i18n from '../../../i18n'
import TestWidget from './testWidget';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router-dom';

class SummariesList extends Component {


  constructor(props) {
    super(props);
    this.state = {
      openKickOff: false,
      openTest: false,
      openMidTerm: false,
      openFinalTest: false,
      openOverview: false
    }
  }

  toggleSummary = (type, state) => {
    this.setState({[type]: !state})
  }

  renderTabLine = (intitule, value, display, weight, type, is_new_score) => {
    const percantageChar = type == 'final_test' && is_new_score && value !== '' ? '%' : ''
    if (display) {
      return (
        <tr valign="top">
           <td className={`intitule ${weight}`}>{intitule}</td>
          <td>{`${value}${percantageChar}`}</td>
         </tr>
      )
    } else {
      return <></>
    }
  }

  renderSummaryRows = (training, type, scope) => {
    if (type == "kickoff") {
      return (
        <tbody>
          <tr>
            <td className="strong">{i18n.t('views.trainings.chart.cv')}
             </td>
            <td>{training?.cv}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.field_history")}

              </td>
            <td>{training?.field_history}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.field_ambition")}
             </td>
            <td>{training?.field_ambitions}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.base_level")}
              </td>
            <td>{training?.base_level}</td>
          </tr>
        </tbody>
      )
    } else if (type == "midterm") {
      return (
        <tbody>
          <tr valign="top">
            <td className="strong">
            {i18n.t("views.lessons.review.teacher_note")}
              </td>
            <td>{training.bpt_resume}</td>
          </tr>
        </tbody>
      )
    } else if (type == "final") {
      return (
        <tbody>
          <tr valign="top">
            <td className="strong"> {i18n.t("views.lessons.review.teacher_note")}</td>
            <td>{training.bff_resume}</td>
          </tr>
        </tbody>
      )
    } else if (type == "test") {
      return (
        <tbody>
            {/* 360 */}
            {this.renderTabLine(i18n.t('views.trainings.test.test_score_total'), training.test_score_360, (scope == "Test 360" || scope == "English 360"), 'strong')}
            {this.renderTabLine(i18n.t('views.trainings.test.listening'), training.test_listening_360, (scope == "Test 360" || scope == "English 360"), 'strong')}
            {this.renderTabLine(i18n.t('views.trainings.test.reading'), training.test_reading_360, (scope == "Test 360" || scope == "English 360"), 'strong')}
            {this.renderTabLine(i18n.t('views.trainings.test.grammar'), training.test_grammar_360, (scope == "Test 360" || scope == "English 360"), 'strong')}
            {this.renderTabLine(i18n.t('views.trainings.test.writing'), training.test_writing_360, (scope == "Test 360" || scope == "English 360"), 'strong')}
            {this.renderTabLine(i18n.t('views.trainings.test.spoken_interaction'), training.test_spoken_int_360, (scope == "Test 360" || scope == "English 360"), 'strong')}
            {this.renderTabLine(i18n.t('views.trainings.test.spoken_production'), training.test_spoken_prod_360, (scope == "Test 360" || scope == "English 360"), 'strong')}
            {/* {this.renderTabLine("Résumé", training.test_resume_360, (scope == "360" && !final),'strong')} */}

            {/* others */}
            {this.renderTabLine(i18n.t('views.trainings.test.test_score_total'), training.test_score_total, (scope != "Test 360" && scope != "English 360"), 'strong')}
            {this.renderTabLine(i18n.t('views.trainings.test.listening'), training.test_score_listening, (scope != "Test 360" && scope != "English 360"), 'strong')}
            {this.renderTabLine(i18n.t('views.trainings.test.reading'), training.test_score_reading, (scope != "Test 360" && scope != "English 360"), 'strong')}
            {/* {this.renderTabLine("Résumé", training.test_resume_others, (scope == "others" && !final))} */}
        </tbody>
      )
    } else if (type == "final_test" && training.test_score_cdl) {
      return (
        <tbody>
            {this.renderTabLine(i18n.t('views.trainings.test.test_score_total'), training.test_score_cdl, true, 'strong', type, training.is_new_score_version)}
            {this.renderTabLine(i18n.t('views.lessons.review.grammar'), training.test_score_cdl_grammar, true, '', type, training.is_new_score_version)}
            {this.renderTabLine(i18n.t('views.trainings.test.listening'), training.test_score_cdl_listening, true, '', type, training.is_new_score_version)}
            {this.renderTabLine(i18n.t('views.trainings.test.reading'), training.test_score_cdl_reading, true, '', type, training.is_new_score_version)}
        </tbody>
      )
    }
  }


  // Point de lancement
  renderKickOff = (training, display, path) => {
    if (display) {
      return (
        <>
          <div className="table-row toggle-title" aria-expanded={this.state.openKickOff} onClick={() => {this.toggleSummary("openKickOff", this.state.openKickOff)}}>
          <div style={{padding:'0px 0px 0px 5px',display: 'flex', justifyContent:'center', alignItems: 'center',
    width: '7%'
}} >{this.state.openKickOff ? <img src={openArrow} /> : <img src={closeArrow} />}</div>

            <span>{training["start_at"]}</span>
            <span className="">
                {i18n.t('views.trainings.kickoff')}
              </span>
          </div>
          <Collapse in={this.state.openKickOff}>
            <div className={`summaries-sub-table-container ${path?.includes('trainings-view') && 'fullWidth' } `}>
              <table className="summaries-sub-table">
                {this.renderSummaryRows(training, "kickoff", "")}
              </table>
            </div>
          </Collapse>
        </>
      )
    }
  }

  // Evaluation certifiante
  renderCertifyingTest = (training, display,path) => {

    if (display) {
      return (
        <>
          <div className="table-row toggle-title" aria-expanded={this.state.openTest} onClick={() => {this.toggleSummary("openTest", this.state.openTest)}}>
          <div style={{padding:'0px 0px 0px 5px',display: 'flex', justifyContent:'center', alignItems: 'center',
    width: '7%'
}} >{this.state.openTest ? <img src={openArrow} /> : <img src={closeArrow} />}</div>
            <span>{training["test_date"]}</span>
            <span className="">
            {i18n.t('views.trainings.chart.certifying_evaluation')}
            </span>
            {/* <div><FontAwesomeIcon size={"2x"} color="#FFC400" className="icon-details" icon={this.state.openTest ? faChevronUp : faChevronDown}/></div> */}
          </div>
          <Collapse in={this.state.openTest}>
          <div className={`summaries-sub-table-container ${path?.includes('trainings-view') && 'fullWidth' } `}>
              <table className="summaries-sub-table">
                {this.renderSummaryRows(training, "test", training["test_type"])}
              </table>
            </div>
          </Collapse>
        </>
      )
    }

  }

  // Bilan intermédiaire
  renderMidTerm = (training, display,path) => {
    if (display) {
      return (
        <>
          <div className="table-row toggle-title" aria-expanded={this.state.openMidTerm} onClick={() => {this.toggleSummary("openMidTerm", this.state.openMidTerm)}}>
          <div style={{padding:'0px 0px 0px 5px',display: 'flex', justifyContent:'center', alignItems: 'center',
    width: '7%'
}} >{this.state.openMidTerm ? <img src={openArrow} /> : <img src={closeArrow} />}</div>
            <span>{training["bpt_date"]}</span>
            <span className="">



            {i18n.t('views.trainings.midterm')}
            </span>
            {/* <div><FontAwesomeIcon size={"2x"} color="#FFC400" className="icon-details" icon={this.state.openMidTerm ? faChevronUp : faChevronDown}/></div> */}
          </div>
          <Collapse in={this.state.openMidTerm}>
          <div className={`summaries-sub-table-container ${path?.includes('trainings-view') && 'fullWidth' } `}>
              <table className="summaries-sub-table">
                {this.renderSummaryRows(training, "midterm", "")}
              </table>
            </div>
          </Collapse>
        </>
      )
    }
  }

  // Evaluation finale
  renderFinalTest = (training, display,path) => {
    if (display) {
      return (
        <>
          <div className="table-row toggle-title" aria-expanded={this.state.openFinalTest} onClick={() => {this.toggleSummary("openFinalTest", this.state.openFinalTest)}}>
          <div style={{padding:'0px 0px 0px 5px',display: 'flex', justifyContent:'center', alignItems: 'center',
    width: '7%'
}} >{this.state.openFinalTest ? <img src={openArrow} /> : <img src={closeArrow} />}</div>
            <span>{training["cdl_test_date"]}</span>
            <span className="">


            {i18n.t('views.trainings.chart.final_evaluation')}</span>
            {/* <div><FontAwesomeIcon size={"2x"} color="#FFC400" className="icon-details" icon={this.state.openFinalTest ? faChevronUp : faChevronDown}/></div> */}
          </div>
          <Collapse in={this.state.openFinalTest}>
          <div className={`summaries-sub-table-container ${path?.includes('trainings-view') && 'fullWidth' } `}>
              <div className="flex-container">
                <div className="sub-container">
                  <h3>{i18n.t('views.trainings.chart.final_evaluation')}</h3>
                  <p>{ ReactHtmlParser (i18n.t("views.trainings.chart.passed_final_test", {date: training?.cdl_test_date}))}</p>
                </div>
                <TestWidget score={training["cdl_test_lvl"]}/>
              </div>
              <table className="summaries-sub-table">
                {this.renderSummaryRows(training, "final_test", "")}
              </table>
            </div>
          </Collapse>
        </>
      )
    }
  }

  // Bilan final
  renderOverview = (training, display,path) => {
    if (display) {
      return (
        <>
          <div className="table-row toggle-title" aria-expanded={this.state.openFinal} onClick={() => {this.toggleSummary("openFinal", this.state.openFinal)}}>
          <div style={{padding:'0px 0px 0px 5px',display: 'flex', justifyContent:'center', alignItems: 'center',
    width: '7%'
}} >{this.state.openFinal ? <img src={openArrow} /> : <img src={closeArrow} />}</div>
            <span>{training["bff_date"]}</span>
            <span className="">
            {i18n.t('views.trainings.overview')}
              </span>
            {/* <div><FontAwesomeIcon size={"2x"} color="#FFC400" className="icon-details" icon={this.state.openFinal ? faChevronUp : faChevronDown}/></div> */}
          </div>
          <Collapse in={this.state.openFinal}>
          <div className={`summaries-sub-table-container ${path?.includes('trainings-view') && 'fullWidth' } `}>
              <table className="summaries-sub-table">
                {this.renderSummaryRows(training, "final", "")}
              </table>
            </div>
          </Collapse>
        </>
      )
    }
  }


  render() {

    const training = this.props.training

    const path = this.props.path


    return (
        <div className="summaries">
          <div className="summaries-table">
            <div className="table-header">
              <span style={{paddingLeft:'44px'}} > {i18n.t('views.lessons.date')}</span>
              <span style={{paddingLeft:'10px'}} >{i18n.t('views.trainings.overview_types')}</span>
            </div>
            <div className="table-body">
              {this.renderKickOff(training, true,path)}
              {this.renderCertifyingTest(training, training["test_done"],path)}
              {this.renderMidTerm(training, training["bpt_done"],path)}
              {this.renderFinalTest(training, training["cdl_test_done"],path)}
              {this.renderOverview(training, training["bff_done"],path)}
            </div>
          </div>
        </div>
      );
    }

}

export default SummariesList;
