import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getTraining } from "./trainingsSlice";
import Header from './components/header';
import TrainingCard from './components/trainingCard/fullWidth';
import SidedTrainingCard from './components/trainingCard/sided';
import Chart from './components/chart';
import Lessons from '../lessons/lessons_embeded';
import Elearnings from '../e-learning/elearnings_embeded';
import Summaries from './components/summaries';
import { Tabs, Tab, Button } from 'react-bootstrap';
import StateManagement from "../lessons/components/state_management";
import Modal from '../toolbox/modal'
import i18n from '../../i18n'
import StepOne from "./components/steps/stepOne.js";
import openArrow from "../images/icons/openArrow.svg";
import closeArrow from '../images/icons/closeArrow.svg'
import StepTwo from "./components/steps/stepTwo";
import StepThree from "./components/steps/stepThree";
import StepFour from "./components/steps/stepFour";
import StepFive from "./components/steps/stepFive";
import Document from "./components/document.js";

export default function TrainingView(){
  const location = useLocation()
  const search = useLocation().search;
  const panelState = new URLSearchParams(search).get('pm');
  const user = useSelector(state => state.user.userData)
  const training = useSelector(state => state.trainings.trainingShow)
  const [key, setKey] = useState("lessons")
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const ref = useRef()
  const navigate = useNavigate();


  const close = () => {
    user?.role === 'Teacher' ? navigate(`/t_trainings?t=${training?.id}&pm=s`) : user?.role==='Admin'  ? navigate(`/a_trainings?t=${training?.id}&pm=s`) :  navigate(`/h_trainings?t=${training?.id}&pm=s`)
  }

  return (

    <div className="main-content lessonShow" >
    <div className={` page-container ${openNavbar && 'responsive-hidden'} ${(panelState == 's' && responsiveOne) && "responsive-panel"}`} >

      <div className="training">
      <div className="actions">
                <div onClick={() => close()} className="icon close-icon"></div>
              </div>
        <div>
          <h3>{training.student} - {training.name}</h3>
          <p style={{'marginBottom': '10px'}}>{training.email} - {training.phone_number}</p>
        </div>
        <div>
          <SidedTrainingCard />

          <div className="tabs-container">
            <Tabs
              defaultActiveKey="lessons"
              id="uncontrolled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="overviews" title={`${i18n.t('overviews')}`}>
                {(key == "overviews") &&
                  <Summaries training={training} path={location?.pathname}  />
                }
              </Tab>
              {
                 user.role == "Admin" &&
                <Tab eventKey="documents" title={`${i18n.t('documents.document')}`}>
                  {(key == "documents") &&
                    <Document training={training} />
                  }
                </Tab>
              }
               <Tab eventKey="elearnings" title={`${i18n.t('elearnings')}`}>
                {(key == "elearnings") &&
                  <Elearnings />
                }
              </Tab>
              <Tab eventKey="lessons" title={`${i18n.t('navigation.admin.lessons')}`}>
                {(key == "lessons") &&
                  <Lessons />
                }
              </Tab>


            </Tabs>
          </div>

        </div>

        <Modal ref={ref}>
          <StateManagement />
        </Modal>
      </div>
    </div>
    </div>

  )
}
