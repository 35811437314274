import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setResponsivePanel } from "../navigation/navigationSlice";


const Panel = ({children, state, unselect, expand}) => {
    const navigate = useNavigate();
    const basePath = useLocation().pathname;
    const location = useLocation()
    const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)
    const search = location.search;
    const lessonId = new URLSearchParams(search).get('l');
    const training= useSelector((state)=>state.trainings.trainingShow)
    const user = useSelector((state)=>state.user.userData)


    const close = () => {
        unselect(undefined)
       (((location?.pathname ==='/t_trainings' || location?.pathname ==='/a_trainings' || location?.pathname ==='/h_trainings') && location?.search?.includes('l') ? user?.role==='Teacher' ? navigate(`/t_trainings?t=${training?.id}&pm=s`) : user?.role==='Admin' ? navigate(`/a_trainings?t=${training?.id}&pm=s`) : navigate(`/h_trainings?t=${training?.id}&pm=s`) : navigate(basePath)  ) )
    }


    return (
        <div className={`panel ${state} ${responsiveOne && "responsive-panel"}`}>
           <div className="actions">
              {(!responsiveOne && (location?.pathname?.includes('lessons') || location?.pathname?.includes('trainings')) )&&  <div onClick={() => expand()}className="icon expand-icon"></div>}
              <div onClick={() => close()} className="icon close-icon"></div>
           </div>
           {state == 's' && children}
        </div>
    )

};

export default Panel;
