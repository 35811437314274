import React from "react";
import Status from "../components/status";
import Issue from '../components/issue';
import i18n from "../../../i18n";
import UpsellButton from "../components/upsellBtn";
import OpenTraining from "../components/openTraining";

const columns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: `${i18n.t('views.trainings.alert')}`,
    width: '20%',
    selector: row =>
      <Issue has_issue={row.ticket_issue} note={row.ticket_issue_note} id={row.id} />

  },
  {
    name: `${i18n.t('views.trainings.chart.upsell')}`,
    selector: row => <UpsellButton trainingId={row.id} ticket_upsell={row?.ticket_upsell} note={row.ticket_upsell_note} />,
    width: "20%",


  },
  {
    name: `${i18n.t('views.trainings.training')}`,
    selector: row => <OpenTraining row={row}><div className="bold">{row.student}</div></OpenTraining>,
    width: "30%"
  },

  {
    name: `${i18n.t('views.trainings.dates')}`,
    selector: row => {
      return (
        <div className="table-sub-list">

          <OpenTraining row={row}> <div className="list-item"><div className="label">DEBUT :</div> {row.start_at}</div>
            <div className="list-item"><div className="label">FIN :</div> {row.end_at}</div></OpenTraining>

        </div>
      )
    },
    width: '20%',


  },
  {
    name: `${i18n.t('views.trainings.status')}`,
    selector: row => {
      return (
        <div className={`table-sub-list ${row.student_status}`}>
          <OpenTraining row={row}> {i18n.t(`activerecord.attributes.student.status.${row.student_status}`)}</OpenTraining>

        </div>
      )
    },
    width: '10%',

  },

];

export default columns
