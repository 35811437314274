import React, {forwardRef, useState, useImperativeHandle} from "react";
import { useDispatch } from "react-redux";
import { resetLessonError } from '../lessons/lessonsSlice';
import { resetCCError } from "../cours-collectives/coursCollectivesSlice";
import { resetAvailabilityError } from "../availabilities/availabilitiesSlice";

const Alert = forwardRef(function Alert({error}, ref) {
    const dispatch = useDispatch()
    const classes = `alert alert-dismissible fade show m-1 react-flash notice alert-warning`
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        onShow() {
            setOpen(true)
        },
        onHide() {
            setOpen(false)
        }
      }));

    const close = () => {
        setOpen(false)
        dispatch(resetLessonError())
        dispatch(resetCCError())
        dispatch(resetAvailabilityError())
    }

    return (
      <div className={classes} role="alert" style={{display: open ? 'block' : 'none' }}>
          {error}
          <button type="button" className="close" onClick={close} >
            <span aria-hidden="true">×</span>
          </button>
        </div>
    )
});

export default Alert;
