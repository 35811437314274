import React from "react";
import { useNavigate } from "react-router-dom";

const OpenTraining = ({row, children}) => {
   const navigate = useNavigate();

    return (
        <span style={{display:'flex', flexDirection:'column'}} onClick={() => {
          navigate(`?t=${row.id}&pm=s`)}}>
            {children}
        </span>
    )

};

export default OpenTraining;
