import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "../../../i18n";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const DownloadReview = () => {
    const user = useSelector(state => state.user.userData)
    const location = useLocation();

    if (user.role == "Student" && location.pathname.includes('s_lessons')) {
        if ((location.pathname.includes('/s_lessons') && user.has_lesson) || (location.pathname.includes('collective_lessons') && user.has_collective_lesson)) {
            return (
                <div className='download-review' >
                    <a href={`/reviews.pdf${location.pathname.includes('collective') ? '?training_id=collective' : ''}`} target="_blank"><div className="general-icon small download"></div></a>
                </div>
            )
        } else {
            return (
                <OverlayTrigger
                    placement={"bottom"}
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>{i18n.t(`views.lessons.no-review.${location.pathname.includes('collective') ? 'collective' : 'single'}`)}</Tooltip>}
                >
                    <div className='download-review empty' >
                        <div className="general-icon small empty"></div>
                    </div>
                </OverlayTrigger>
                )
        }
    } else {
        return <></>
    }

};

export default DownloadReview;
