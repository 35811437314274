import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { fetchTrainings, resetTrainings } from "./trainingsSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from '../toolbox/datatable';
import Columns from './columns/columns';
import HrColumns from './columns/hrColumns';
import SidedColumns from './columns/sided';
import HrSidedColumns from './columns/hrColumnSided';
import Panel from '../toolbox/panel';
import Training from './training';
import Header from './components/header';
import Modal from '../toolbox/modal';
import TrainingManagement from "./components/trainingManagement";
import CardList from "../toolbox/cardList";
import TrainingListItem from "./components/trainingListItem";
import i18n from '../../i18n'
import Lesson from '../lessons/lesson'
import TrainingView from "./trainingView";

//column rendering depending of role / sided

const Trainings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const path = location.pathname
  const filters = useSelector(state => state.filters.filtersTraining);
  const panelState = new URLSearchParams(search).get('pm');
  const user = useSelector(state => state.user.userData);
  const { trainingsList, loading } = useSelector((state) => state.trainings)
  const trainingId = new URLSearchParams(search).get('t');
  const lessonId = new URLSearchParams(search).get('l')
  const [currentRow, setCurrentRow] = useState(null);
  const [columns, setColumns] = useState(Columns);
  const [page, setPage] = useState(1);
  const ref = useRef(null);
  const modalRef = useRef(null);
  const { store } = useContext(ReactReduxContext)
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const profile = useSelector((state) => state.user.currentProfile)


  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersTraining
    setPage(1)
    dispatch(resetTrainings())
    dispatch(fetchTrainings({ page: 1, filters }))
  }, [filters, profile]);


  useEffect(() => {
    if (panelState == 's') {

      setColumns(user?.role === 'HumanResource' ? HrSidedColumns : SidedColumns)

    } else {

      setColumns(user?.role === 'HumanResource' ? HrColumns : Columns)

    }
  }, [panelState, user?.role]);

  useEffect(() => {
    if (panelState == 'm') {
      modalRef.current?.onShow()
    } else {
      modalRef.current?.onHide()
    }
  }, [panelState]);

  useEffect(() => {
    (currentRow) &&
      navigate(`?t=${currentRow?.id}&pm=s`)
  }, [currentRow]);

  const expand = () => {

    let route
    if (location?.search?.includes('l')) {
      if (user?.role === 'Teacher') {

        route = `/t_trainings?l=${lessonId}&pm=f`
      } else if (user?.role==='Admin') {
        route = `/a_trainings?l=${lessonId}&pm=f`
      } else {
        route = `/h_trainings?l=${lessonId}&pm=f`
      }
    }
    else {
      if (user?.role === 'Teacher') {

        route = `/t_trainings?t=${trainingId}&pm=f`
      } else if (user?.role==='Admin') {
        route = `/a_trainings?t=${trainingId}&pm=f`
      } else {
        route = `/h_trainings?t=${trainingId}&pm=f`
      }
    }

     navigate(route)
  }

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersTraining
    if (page && !loading) {
      dispatch(fetchTrainings({ page, filters }))
    }
  }, [page, profile]);



  return (

    <div className={`main-content trainings`}>

      <div className={`datatable page-container ${(openNavbar || responsiveFilter || (responsiveOne && panelState == 's')) && 'responsive-hidden'} ${panelState == 's' && 'sided'}`} >

      { (lessonId && panelState ==='f' ) && <Lesson/> }

        {panelState === 'f' && <TrainingView />}


        <Header />

        {

          !responsiveOne ?
            <DataTable
              columns={columns}
              data={trainingsList}
              onRowClicked={(row) => setCurrentRow(row)}
              setPassedPage={setPage}
              passedPage={page}
              loading={loading}
              ref={ref}
              filters={filters}
            /> :
            <CardList
              data={trainingsList}
              onRowClicked={(row) => setCurrentRow(row)}
              setPassedPage={setPage}
              passedPage={page}
              loading={loading}
              // futurePage={futurePage}
              // setFuturePage={""}
              ref={ref}
              rootPage='training'

              card={<TrainingListItem />}
            />

        }

      </div>
      <Panel state={panelState} unselect={setCurrentRow} expand={expand}>
        {location?.search?.includes('l') ? <Lesson key={trainingId} /> : <Training key={trainingId} />}
      </Panel>
      <Modal ref={modalRef}>
        <TrainingManagement />
      </ Modal>

    </div>

  )




};

export default Trainings;
