import React, { useRef, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import i18n from '../../../i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function InactifTeacher() {

  const teacher = useSelector((state) => state.teachers.teacherShow)
  const classes = `teacher-inactive-alert fade show react-flash notice alert-warning`
  const hideReason = teacher?.inactive_reason?.length === 0

  const overlay = (
    <Popover id="popover-basic">
      <Popover.Content style={{ padding: '10px' }}>
        {teacher?.inactive_reason?.map((reason, index) => (
          <div key={index}>
            <li>
              <span>{reason}</span>
            </li>
          </div>
        ))}
      </Popover.Content>
    </Popover>
  )

  const renderMessage = () => {

    let message = ''
    if (teacher?.active_status === i18n.t('activerecord.attributes.teacher.active_status.inactive')) {
      message = i18n.t('activerecord.attributes.teacher.inactive_warning')
    }
    else if (teacher?.active_status === i18n.t('activerecord.attributes.teacher.active_status.unassignable')) {
      message = i18n.t('activerecord.attributes.teacher.unassignable_warning')
    }
    else if (teacher?.active_status === i18n.t('activerecord.attributes.teacher.active_status.notice_given')) {
      message = i18n.t('activerecord.attributes.teacher.notice_given_warning')
    }
    else if (teacher?.active_status === i18n.t('activerecord.attributes.teacher.active_status.blocked')) {
      message = i18n.t('activerecord.attributes.teacher.blocked_warning')
    }


    return (
      <span> {message} </span>
    )
  }

  return (
    (teacher?.active_status === i18n.t('activerecord.attributes.teacher.active_status.inactive') || teacher?.active_status === i18n.t('activerecord.attributes.teacher.active_status.unassignable') || teacher?.active_status === i18n.t('activerecord.attributes.teacher.active_status.blocked') || teacher?.active_status===i18n.t('activerecord.attributes.teacher.active_status.notice_given')) && (

      <div className={classes} >

        {renderMessage()}

        {<OverlayTrigger
          trigger="hover"
          placement="bottom"
          containerPadding={20}
          overlay={
            !hideReason ? overlay : <span></span>
          }
        >
         <FontAwesomeIcon color={hideReason && 'gray'} width={40} style={{ cursor: hideReason ? 'cursor' : 'pointer' }} icon={faCircleInfo} />

        </OverlayTrigger>}

      </div>
    )
  );

}
