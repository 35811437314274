import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



  export const fetchCourseCollectives = createAsyncThunk(
    'user/fetchCourseCollectives',
    async ({filters, page, per_page}) => {
      try {
        const response = await axios.get(`api/v2/collective_lessons?filters=${JSON.stringify(filters)}&page=${(page)}${per_page ? `&per_page=${per_page}` : ''}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const beSubscribeCourseLesson = createAsyncThunk(
    'user/beSubscribeCourseLesson',
    async ({lesson_id, student_id, training_id}, thunkAPI) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      const body = {
        lesson_id: lesson_id,
        student_id: student_id,
      }

      try {
        const response = await axios.post(`/api/v2/attendances`, body, {
          headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        if (!error.response) {
          throw error
        }
        return thunkAPI.rejectWithValue(error.response.data?.message[0]);
      }
    }
  )

const coursCollectivesSlice = createSlice({
  name: 'courseCollectives',
  initialState: {
    loading: false,
    courseCollectivelist: [],
    courseCollectiveShow:{},
    error:''
  },
  reducers: {
    setCalendarLessons: () => {
      state.courseCollectivelist = action.payload
    },
    resetCourseCollective: (state, action) => {
      state.courseCollectivelist = []
    },

    setCourseCollectiveShow:()=>{
      state.courseCollectiveShow= action.payload
    },
    resetCCError: (state, action) => {
      state.error = ''
    }


  },
  extraReducers: (builder) => {
    builder.addCase(fetchCourseCollectives.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true
        state.currentRequestId = action.meta.requestId
      }
    }),
    builder.addCase(fetchCourseCollectives.fulfilled, (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading &&
        state.currentRequestId === requestId
      ) {
        state.loading = false
        state.courseCollectivelist = action.payload
        state.currentRequestId = undefined
      }
    }),
    builder.addCase(beSubscribeCourseLesson.fulfilled, (state, action) => {

      state.courseCollectiveShow=action.payload

      let oldList = state.courseCollectivelist.filter(e => {
        return e?.id === action.payload.id
      })

      const index = state.courseCollectivelist.indexOf(oldList[0])
      let copiedState = state.courseCollectivelist.slice(0);
      copiedState[index] = action.payload
      state.courseCollectivelist = copiedState;
    }) ,
    builder.addCase(beSubscribeCourseLesson.rejected, (state, action) => {
     state.error = action.payload
    })



  }
})

export const coursCollectivesList = createSelector(
  (state) => ({
    courseCollectivelist: state.courseCollectivelist,
    subscribeCheck: state.subscribeCheck,
    error:state.error

  }),
  (state) => state.coursCollectivesList
)

export const {  setCalendarLessons, resetCourseCollective,resetCCError } = coursCollectivesSlice.actions
export default coursCollectivesSlice.reducer
