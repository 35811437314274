import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeacher } from "./teachersSlice";
import TeacherProfile from "./teacher_profile";
import Modal from "../toolbox/modal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from "react-bootstrap";

const Avatar = ({ calendar }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.userData)
  const teacherId = user.teacher
  const teacher = useSelector(state => state.teachers.teacherShow)
  const ref = useRef(null);
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const teacherIdAdminAgenda = useSelector(state => state.filters['filtersLesson'].teachers)
  const profile = useSelector((state)=>state.user.currentProfile)


  useEffect(() => {
    if (teacherId) {
      dispatch(getTeacher(teacherId))
    }
  }, [teacherId,profile]);

  useEffect(() => {
    if (teacherIdAdminAgenda) {
      dispatch(getTeacher(teacherIdAdminAgenda?.value))
    }
  }, [teacherIdAdminAgenda,profile]);

  const showModal = () => {
    ref.current?.onShow()
  }

  const overlay = (
    <Popover id="popover-basic">
      <Popover.Content>
        {`${teacher?.fill_rate}% occupation`} <br />
        Règle : <br />
        [0%-80%] vert <br />
        [80%-100%] jaune <br />
        [&gt;100%] rouge
      </Popover.Content>
    </Popover>
  );

  const circleColor = (fillRate) => {
    if (fillRate >= 100) {
      return "#cc8574";
    } else if (fillRate < 100 && fillRate >= 80) {
      return "#FFC400";
    } else {
      return "#1abc9c";
    }
  }


  if (calendar) {
    return (
      <div className={`calendar-avatar-container ${responsiveOne && "responsive"}`}>
        {user?.role === 'Admin' && (
          <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={overlay}>
            <span style={{ display: 'inline-block', paddingTop: '18px', marginRight: '-36px', position: 'relative' }}>
              {Object.keys(teacher).length !== 0 && (
                <FontAwesomeIcon
                  icon={faCircle}
                  color={circleColor(teacher?.fill_rate)}
                  style={{ marginRight: '10px' }}
                />
              )}
            </span>
          </OverlayTrigger>
        )}

        <div className={`avatar ${responsiveOne && "responsive"}`} style={{ backgroundImage: `url(${teacher?.avatar?.file_url}` }} onClick={showModal}></div>
        <Modal ref={ref}>
          <TeacherProfile />
        </ Modal>
      </div>
    )
  } else {
    return (
      <>
        <div className={`avatar ${responsiveOne && "responsive"}`} style={{ backgroundImage: `url(${teacher?.avatar?.file_url}` }}></div>
        <Modal ref={ref}>
          <TeacherProfile />
        </ Modal>
      </>
    )
  }


};

export default Avatar;
