import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersTickets, setResponsiveFilterCleanBtn} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'
import { useLocation } from "react-router-dom";

const ByLessonStatus = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const user = useSelector(state => state.user.userData);
	const current = useSelector(state => state.filters[model].states)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef()

  const location = useLocation();
  const path = location.pathname

	const [options, setOptions] = useState([
		{ value: "canceled", label: `${i18n.t('activerecord.attributes.lesson_transition.state.canceled')}` },
		{ value: "canceled_by_teacher", label:`${i18n.t('activerecord.attributes.lesson_transition.state.canceled_by_teacher')}` },
		{ value: "passed", label: `${i18n.t('activerecord.attributes.lesson_transition.state.passed')}` },
		{ value: "done", label: `${i18n.t('activerecord.attributes.lesson_transition.state.done')}`  },
		{ value: "late_cancelation", label:`${i18n.t('activerecord.attributes.lesson_transition.state.late_cancelation')}`},
		{ value: "noshow", label: "Absent" },
		{ value: "booked", label: `${i18n.t('activerecord.attributes.lesson_transition.state.one')}`  },
		{ value: "recredit", label: `${i18n.t('activerecord.attributes.lesson_transition.state.recredit')}`  },
		{ value: null, label: `${i18n.t('active_admin.empty')}` },
	])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersLesson": (value) => dispatch(setFiltersLesson({states: value})),
		"setFiltersTickets": (value) => dispatch(setFiltersTickets({states: value}))
	}

	const  onSelectChange = value => {
	// 	if(responsiveFilter){
    //   dispatch(setResponsiveFiltersArray({action,value,type:'lessonStatus', model}))
    // }
    // else{
      dispatcher[action](value)
    //}
	}

  if(isCleaned){
    ref.current?.select.clearValue();

  }

	return (
		<div className='filter by_states'>
			<div className="line">
				<label htmlFor="filter_type" id="states" className="s_label">{i18n.t('views.invoices.status')}</label>
				<Select
					ref={ref}
					isLoading={loading}
					isDisabled={loading}
					cacheOptions
					onChange={onSelectChange}
					isMulti
					options={ path?.includes('/s_lessons') && user.role == "Student" ? options?.filter((item)=> item?.value!==null) : options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByLessonStatus;
