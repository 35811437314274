
import React, {useEffect, useState, useContext} from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchOneExercise } from "./elearningSlice"
import checkIcon from '../images/icons/checkIcon.svg'
import unassignBtn from '../images/icons/unassignee-btn.svg'
import temporaryImg from '../images/icons/white-space.svg'
import playBtn from '../images/icons/play-btn-white.svg'
import unassigneBtnGreen from '../images/icons/unassignebtn-green.svg'
import assigneeBtnGreen from '../images/icons/assigneBtn-green.svg'
import AssigneButton from "./components/assigneButton"
import i18n from "../../i18n";
import { ProgressBar } from "react-bootstrap";



export default function ELearning() {

  const dispatch = useDispatch()
  const search = useLocation().search;
  const exerciceId = new URLSearchParams(search).get('e');
  const exercise = useSelector((state) => state.elearnings.exercise)
  const changeIconAfterAssigne = useSelector((state) => state.elearnings.changeIconAfterAssigne)
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const panelState = new URLSearchParams(search).get('pm');
  const [link, setLink] = useState(temporaryImg)
  const { store } = useContext(ReactReduxContext)


  useEffect(() => {
    dispatch(fetchOneExercise(exerciceId))
  }, [exerciceId])

  useEffect(() => {
    const state = store.getState();
    const exercise = state.elearnings.exercise
    if (exercise?.image_link && exercise?.id == exerciceId){
      const id = exercise?.image_link.split('/')[5]
      id && setLink(`https://drive.google.com/thumbnail?id=${id}&sz=w1000`)
    } else {
      setLink(temporaryImg)
    }
  }, [exercise])


  return (
    <div className={` page-container ${openNavbar && 'responsive-hidden'} ${(panelState == 's' && responsiveOne) && "responsive-panel"}`} >
      <div className="container-elearning">
        <div className="header">
          <div className="top-row">

            <div className="header-strings">
              <span className={`header-text ${(panelState == 's' && responsiveOne) && "responsive-tag"}  `}>{exercise?.name}  </span>
            </div>

          </div>




          <div className="header-btn-icon">

            {
              <a href={exercise?.exercice_link} target="_blank" className={`link-btn ${exercise?.state ? exercise?.state : 'assign'}`}>
                <img style={{ width: "24px", height: "24px", marginRight: '2px' }} src={playBtn} />
                <span> { exercise?.state === 'done' ? i18n.t('views.elearnings.redo'):  i18n.t('views.elearnings.start')}</span>
              </a>
            }
            {
              (exercise?.state === 'done') &&
              <img className="check-icon" src={checkIcon} />
            }
            <AssigneButton state={exercise?.state} sided={true} id={exercise?.id} assigne={exercise?.assignment} canUnassign={exercise?.can_unassign} />
          </div>

        </div>

        <div className="content">
          <div style={{ marginBottom: '25px', boxShadow:' 0 2px 5px rgba(0, 0, 0, 0.2)' }}>
            <img className="video" src={link} />
          </div>

          <div style={{ marginBottom: '25px' }}>
            <h4>{i18n.t('views.elearnings.progress')} :</h4>
            <ProgressBar style={{height:30, borderRadius:10}} variant="success" now={exercise?.progress === null ? 0 : exercise.progress} label={`${exercise?.progress === null ? 0 : exercise?.progress}%`} />

          </div>

          <div style={{ marginBottom: '25px' }}>
            <h4 >{i18n.t('views.elearnings.goal')} :  </h4>
            <p className="exercice-value" >{exercise?.objective}</p>
          </div>

          <div style={{ marginBottom: '25px' }}>
            <h4>{i18n.t('views.elearnings.average_time')} :</h4>
            <p className="exercice-value"> {exercise?.average_time} </p>

          </div>


          <div style={{ marginBottom: '25px' }}>
            <h4>{i18n.t('views.elearnings.score')} :</h4>
            <p className="exercice-value"> {exercise?.score} </p>


          </div>
          <div style={{ marginBottom: '25px' }}>
            <h4>{i18n.t('views.elearnings.attempts')} :</h4>
            <p className="exercice-value"> {exercise?.attempt_count} </p>

          </div>
          <div style={{ marginBottom: '25px' }}>
            <h4>{i18n.t('views.elearnings.time_spent')} :</h4>
            <p className="exercice-value"> {exercise?.time_spent} </p>

          </div>


        </div>
      </div>
    </div>
  )
}
