

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Filters from "../../filters/filters";
import i18n from "../../../i18n";
import ActiveFilters from './../../filters/activeFilters'

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname
    const user = useSelector(state => state.user.userData)

    return (
        <>
        <div className="main-header">
            <div className="main-title">
                <h2>{i18n.t('activerecord.attributes.elearning.title')}</h2>
            </div>
            <div className="main-actions">
                <div className="actions">
                        <div className="list-switch">
                            <Link className="switch-el" to={'/elearnings'}>
                                <div className={`icon elearnings ${location.pathname == '/elearnings' ? 'active' : ''}`}></div>
                            </Link>
                            <Link className="switch-el" to={'/elearnings_card_view'}>
                                <div className={`icon cards ${location.pathname == '/elearnings_card_view' ? 'active' : ''}`}></div>
                            </Link>
                        </div>
                </div>
                <Filters model={"ELearning"}/>
            </div>
        </div>
            <ActiveFilters model={"filtersELearning"} action={"setFiltersELearning"} />
        </>
    )
};

export default Header;
