import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LessonActions from './lessonActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboard, faLock, faTimes, faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from "react-select/async";
import { Button } from 'react-bootstrap';
import i18n from '../../../i18n'
import TableDemandAction from "../../tickets/columns/tableDemandAction";
import { deleteAvailability } from "../../availabilities/availabilitiesSlice";
import { handleTicket } from "../../tickets/ticketsSlice";

const EventDetail = ({ event, source }) => {
  const user = useSelector(state => state.user.userData)
  const navigate = useNavigate();
  const [selectedStudent, setSelectedStudent] = useState()
  const dispatch = useDispatch()

  const loadStudentOptions = (inputValue, callback) => {
    const url = `/api/v2/filters/get_student_options?input=${inputValue}&availability_id=${event.publicId}`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    const promise = fetch(url, { credentials: "same-origin" }).then(r =>
      r.json()
    ).then(data => {
      callback(data)
    });
  }

  const onSelectChange = value => {
    //admin single booking
    navigate(`/teacher_agenda/?a=${event.publicId}&pm=m&student=${value.value}&state=admin_single_booking`)
  }

  const onStudentChange = (value) => {
    //admin collective booking
    setSelectedStudent(value.value)
  }

  const subscribe = (student_id, lesson_id) => {
    //admin collective booking
    navigate(`/teacher_agenda/?l=${lesson_id}&pm=m&student=${student_id}&state=admin_collective_booking`)
  }

  const unsubscribe = (student_id) => {
    navigate(`/teacher_agenda/?l=${event.publicId}&pm=m&student=${student_id}&state=admin_collective_canceling`)
  }

  const renderAttendingState = (state) => {
    if (state == "show") {
      return <FontAwesomeIcon icon={faCheck} color="#1ABC9C" />
    } else if (state == 'noshow') {
      return <FontAwesomeIcon icon={faTimes} color="#d16a6b" />
    } else {
      return <></>
    }
  }

  const renderStudentList = (students) => {
    if (students.length > 0) {
      return (
        <ul className="tooltip-student-list">
          {students.map((student, idx) => {
            let string
            if (user.role == "Teacher") {
              string = `${student["name"]} - ${student.email} - ${student.phone}`
            } else {
              string = `${student["name"]}`
            }

            if (user.role == undefined || user.role == "Teacher" || !(`${event.extendedProps?.lesson_state}` == "booked")) {
              return (
                <li key={idx} style={{ cursor: "default" }}>
                  {string} {renderAttendingState(student["state"])}
                </li>
              )
            } else {
              return (
                <li key={idx} style={{ cursor: "default" }}>
                  {string} {renderAttendingState(student["state"])} <FontAwesomeIcon className="icon-delete" style={{ cursor: "pointer" }} icon={faTimes} color="#FF0000" onClick={() => { unsubscribe(student['id']) }} />
                </li>
              )
            }
          })
          }
        </ul>
      )
    } else {
      return (
        <ul className="tooltip-student-list">
          <li style={{ cursor: "default" }}>
            O inscrit
          </li>
        </ul>
      )
    }
  }

  const subscribeStudent = (role, lessonId) => {
    if (role == "Admin" && (event.extendedProps?.lesson_state == "booked" || event.extendedProps?.lesson_state == null) && event.extendedProps?.attendances.length < 6) {
      return (
        <div className="line subscribe-student">
          <AsyncSelect
            cacheOptions
            onChange={onStudentChange}
            noOptionsMessage={() => `${i18n.t('filters.start_search')}`}
            loadOptions={loadStudentOptions}
            placeholder={`${i18n.t('helpers.select.prompt')}...`}
          />
          <Button onClick={() => { subscribe(selectedStudent, lessonId) }}>+</Button>
        </div>
      )
    } else {
      return <></>
    }
  }

  const handleRemoveAvailability = () => {
    dispatch(deleteAvailability({ availability_id: event?.publicId, ticket: { note: '', app_document: '' } }))?.then((action) => {
      dispatch(handleTicket({ id: action?.payload?.ticket_id, state: 'validated', note: '' }))
    })
  }

  const renderEventBody = () => {
    if (user.role == "Admin" && event.title == "Availability") {
      return (
        <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
          <AsyncSelect
            styles={{
              container: (base) => ({
                ...base,
                width: '100%',
              }),
            }}
            cacheOptions
            onChange={onSelectChange}
            noOptionsMessage={() => `${i18n.t('filters.start_search')}`}
            loadOptions={loadStudentOptions}
            placeholder={`${i18n.t('helpers.select.prompt')}...`}
          />
          <FontAwesomeIcon  style={{ cursor: 'pointer', marginTop:8 }} onClick={() => { handleRemoveAvailability() }} icon={faTrash} />

        </div>

      )

    }

    else if (user.role == "Admin" && event.title == i18n.t(`activerecord.attributes.availability.pending_closing`)
    ) {
      return (
        <div style={{ marginBottom: '8px' }}>
          <TableDemandAction data={event?.state} id={event?.extendedProps?.ticket_id} />
        </div>
      )

    }
    else if (event.extendedProps.lesson_type == "collective")
      return (
        <div className="tooltip-block">
          <p><span className="tooltip-subtitle">{event.extendedProps.english_type} - {event.extendedProps.theme}</span></p>
          {renderStudentList(event.extendedProps.attendances)}
          {subscribeStudent(user.role, event.publicId)}
        </div>
      )
    else {
      return (
        <>
        </>
      )
    }
  }


  const renderEventHeader = () => {
    if (event.title == "Availability") {
      return <></>
    } return (
      <div className="event-title">

        <h3>{event?.extendedProps?.student} - <span className="small">{i18n.t(`activerecord.attributes.lesson_transition.state.${event.extendedProps?.lesson_state ? event.extendedProps?.lesson_state : 'pending'}`)}</span></h3>
      </div>
    )
  }

  const renderReview = () => {
    if (['done', 'passed'].includes(event.extendedProps?.lesson_state)) {
      let link = user.role === "Teacher" ? `/t_lessons?l=${event.publicId}&pm=s` : user?.role==='Admin' ? `/a_lessons?l=${event.publicId}&pm=s` :`/s_lessons?l=${event.publicId}&pm=s`
      if (event.extendedProps?.lesson_state == "passed" && user.role == "Teacher") {
        return <Link className="btn btn-primary" to={link}>{i18n.t('views.review.fill')}</Link>
      } else {
        return <Link className="btn btn-primary" to={link}>{i18n.t('views.review.show')}</Link>
      }
    } else {
      return <></>
    }
  }

  const renderEventFooter = () => {
    if (event.title == "Availability") {
      return <></>
    } else {
      if (event.extendedProps?.lesson_type == "collective") {
        if (['done', 'passed'].includes(event.extendedProps?.lesson_state)) {
          return (
            <div className="event-footer">
              {renderReview()}
            </div>
          )
        } else {
          return <></>
        }
      } else {
        return (
          <div className="event-footer">
            {renderReview()}
            <LessonActions id={event.publicId} state={event.extendedProps?.lesson_state} start={event.extendedProps?.start} event={event.extendedProps} is_passed={event.extendedProps?.is_passed} />
          </div>
        )
      }
    }
  }

  return (
    <div className='event-detail'>
      {renderEventHeader()}
      <div className="event-body">
        {renderEventBody()}
      </div>
      {renderEventFooter()}
    </div>
  )

};

export default EventDetail;
