import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


  export const fetchCalendarAvailabilities = createAsyncThunk(
    'user/fetchCalendarAvailabilities',
    async (props) => {
      try {
        const response = await axios.get(`/api/v2/availabilities/calendar_availabilities?filters=${JSON.stringify(props)}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const fetchWeekType = createAsyncThunk(
    'user/fetchWeekType',
    async (props) => {
      try {
        const response = await axios.get(`/api/v2/availabilities/week_type?filters=${JSON.stringify(props)}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const fetchGroupedAvailabilities = createAsyncThunk(
    'user/fetchGroupedAvailabilities',
    async (props) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.get(`/api/v2/availabilities?filters=${JSON.stringify(props)}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const getAvStateForm = createAsyncThunk(
    'user/getAvStateForm',
    async ({id, wanted_state, student}) => {
      try {
        const response = await axios.get(`/api/v2/availabilities/${id}/state_form?wanted_state=${wanted_state}&student=${student}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const createAvailability = createAsyncThunk(
    'user/createAvailability',
    async ({availability}) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.post(`/api/v2/availabilities?availability=${JSON.stringify(availability)}`, [], {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const createSlot = createAsyncThunk(
    'user/createSlot',
    async ({slot, teacher},thunkAPI) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.post(`/api/v2/slots`, { slot,teacher}, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        if (!error.response) {
          throw error
        }
        return thunkAPI.rejectWithValue('Something went wrong');
      }
    }
  )

  export const closeSlot = createAsyncThunk(
    'user/closeSlot',
    async ({slot, note}) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.patch(`/api/v2/slots/close`, {slot, note}, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const deleteAvailability = createAsyncThunk(
    'user/deleteAvailability',
    async ({availability_id, ticket = {}}) => {

      const form = new FormData();
      if (ticket.app_document) {
        form.append("ticket[app_document_attributes][file]", ticket.app_document);
      }
      form.append("ticket[expected]", "canceling");
      if (ticket.note) {
        form.append("ticket[note]", ticket.note);
      }
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.patch(`/api/v2/availabilities/${availability_id}/canceling`, form, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

const availabilitiesSlice = createSlice({
  name: 'availabilities',
  initialState: {
    loading: false,
    availabilitiesCal: [],
    avStateForm: {},
    currentRequestId: "",
    weekType: [],
    error:''
  },
  reducers: {
    setCalendarAvailabilities: () => {
      state.availabilitiesCal = action.payload
    },
    setGroupedAvailabilities: () => {
      state.availabilitiesCal = action.payload
    },
    setPendingRequestId(state, action) {
      state.pendingRequestId = action.payload;
    },
    setAvStateForm: () => {
      state.avStateForm = action.payload
    },
    setWeekType: () => {
      state.weekType = action.payload
    },
    resetAvailabilityError: (state, action) => {
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCalendarAvailabilities.fulfilled, (state, action) => {
      state.availabilitiesCal = action.payload
    }),
    builder.addCase(fetchGroupedAvailabilities.fulfilled, (state, action) => {
      state.groupedAvailabilities = action.payload
    }),
    builder.addCase(fetchWeekType.fulfilled, (state, action) => {
      state.weekType = action.payload
    }),
    builder.addCase(getAvStateForm.fulfilled, (state, action) => {
      state.avStateForm = action.payload
    }),
    builder.addCase(createAvailability.fulfilled, (state, action) => {
      state.availabilitiesCal = [...action.payload, ...state.availabilitiesCal]
    }),
    builder.addCase(createSlot.fulfilled, (state, action) => {
      state.weekType = [...action.payload, ...state.weekType]
    }),
    builder.addCase(createSlot.rejected, (state, action) => {
          state.error = action.payload
        }),
    builder.addCase(deleteAvailability.fulfilled, (state, action) => {
        //debugger;
        let oldList = state.availabilitiesCal.filter(e => {
          return e.id === action.payload.id
        })
        const indexCal = state.availabilitiesCal.indexOf(oldList[0])
        let copiedStateCal = state.availabilitiesCal.slice(0);
        copiedStateCal[indexCal] = action.payload
        state.availabilitiesCal = copiedStateCal;
    })
    builder.addCase(closeSlot.fulfilled, (state, action) => {
      let oldCalList = state.weekType.filter(e => {
        return e.id === action.payload?.id
      })
      const indexCal = state.weekType.indexOf(oldCalList[0])
      let copiedStateCal = state.weekType.slice(0);
      copiedStateCal[indexCal] = action.payload
      state.weekType = copiedStateCal;
  })
  }
})

export const availabilitiesList = createSelector(
  (state) => ({
    availabilitiesCal: state.availabilitiesCal,
    avStateForm: state.avStateForm,
    groupedAvailabilities: state.groupedAvailabilities
  }),
  (state) => state.availabilitiesCal
)

export const { setCalendarAvailabilities, setAvStateForm, setGroupedAvailabilities,resetAvailabilityError } = availabilitiesSlice.actions
export default availabilitiesSlice.reducer
